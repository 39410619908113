.page-common .sec-content .page-current-situtation {
	.text {
		line-height: 2;
		& + .text {
			margin-top: 1.75em;
		}
	}
	dl.current-situtation {
		margin-bottom: 6rem;
		@include sp {
			margin-bottom: 4rem;
		}
		dt {
			position: relative;
			z-index: 1;
			font-size: 1.3125rem;
			font-weight: bold;
			padding-top: 3rem;
			padding-bottom: 3rem;
			span {
				display: inline-block;
				background-color: white;
				padding-right: 2em;
				@include sp {
					padding-right: 1em;
				}
			}
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: -1;
				margin: auto;
				height: 1px;
				background-color: #979797;
			}
		}
		dd {
			overflow: hidden;
			.current-situtation-table {
				table-layout: fixed;
				border-collapse: separate;
				border-spacing: 10px;
				max-width: 720px;
				width: 100%;
				margin: -10px;
				@include sp {
					display: block;
					margin: 0;
					thead {
						display: none;
					} 
					tbody, tr, th, td {
						display: block;
					}
				}
				th {
					font-weight: normal;
					line-height: 1.75;
				}
				thead {
					th {
						background-color: #F4F3EE;
						padding: .25rem;
					}
				}
				tbody {
					th, td {
						text-align: left;
						font-weight: normal;
						line-height: 1.5;
						padding: .5rem 1rem;
					}
					@include sp {
						tr {
							margin-bottom: 1.5rem;
						}
						th, td {
							padding: .25rem 0;
						}
						th {
							font-weight: bold;
						}
						td {
							display: flex;
							align-items: center;
							&::before {
								flex: none;
								align-self: stretch;
								display: inline-flex;
								align-items: center;
								justify-content: center;
								background-color: #F4F3EE;
								min-width: 7em;
								font-size: .875rem;
								text-align: center;
								padding: .25rem;
								margin-right: .5em;
							}
							&:nth-of-type(1) {
								&::before {
									content: "平成24年度";
								}
							}
							&:nth-of-type(2) {
								&::before {
									content: "参考";
								}
							}
						}
					}
				}
			}
		}
	}
}
