.page-common .sec-content .page-access {
	.map {
		width: 100%;
		min-height: 600px;
		margin-bottom: 5rem;
		@include sp {
			min-height: 300px;
			margin-bottom: 2rem;
		}
	}
	address {
		font-size: 1.125rem;
		font-style: normal;
		.text + .text {
			margin-top: 1.5em;
		}
	}
	.table_access{
		width: 100%;
		text-align: left;
		background:  #eee;
		border-collapse: collapse;
		tr:nth-child(2n+1) {
		    background-color: rgba(255,255,255,0.50);
		}
		th,td{
			padding: 10px;
		    font-size: 87.5%;
		    line-height: 1.3;
		    border:1px solid #ddd;
		}
	}
}
