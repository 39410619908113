.page-common .sec-content .page-law {
	.common-h2 {
		margin-bottom: 4rem;
	}
	.text {
		line-height: 2;
		& + .text {
			margin-top: 1.75em;
		}
	}
	.links {
		margin-top: 2rem;
		dt {
			font-size: 1.125rem;
			font-weight: bold;
			margin-bottom: 2rem;
		}
	}
}
