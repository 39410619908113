.page-common .sec-content .archive-info {
	.grid-container {
		.grid-main {
			.link_title{
				a{
					color: #000;
					&:hover{
						text-decoration: underline;
					}
				}
			}
			.btn_wrap{
				text-align: right;
			}
		}
		.grid-side {
			.side-notices {
				line-height: 1.5;
				font-size: .75rem;
				& > div {
					margin-bottom: 1rem;
					dd.time {
						@include sp {
							font-size: .875rem;
						}
					}
					dt {
						font-weight: bold;
						@include sp {
							font-size: 1rem;
							font-weight: normal;
						}
						a {
							color: inherit;
							@include sp {
								color: $link-color;
							}
						}
					}
				}
			}
			.side-archives {
				line-height: 1.5;
				font-size: .8125rem;
				list-style: none;
				padding-left: 0;
				@include sp {
					font-size: 1rem;
				}
				li {
					margin-bottom: .5rem;
					a {
						color: inherit;
						text-decoration: none;
						&:hover,
						&:focus {
							text-decoration: underline;
						}
						@include sp {
							color: $link-color;
						}
					}
				}
			}
		}
	}
}
