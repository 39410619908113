// mixinsの読み込み
@import "./mixins/include.scss";
@import "_variables.scss";
/******************************************
* font-size
******************************************/
@mixin font-size($size) {
    font-size:#{72 + (7 * ($size - 10)) * 1%};
}

/******************************************
* ie11のみ適応
******************************************/
@mixin ie11() {
    @media all and (-ms-high-contrast:none){
        @content;
    }
}

@for $i from 0 through 30 {
    .mt#{$i * 5} {margin-top:#{$i * 5}px!important;}
    .mr#{$i * 5} {margin-right:#{$i * 5}px!important;}
    .mb#{$i * 5} {margin-bottom:#{$i * 5}px!important;}
    .ml#{$i * 5} {margin-left:#{$i * 5}px!important;}

    .pt#{$i * 5} {padding-top:#{$i * 5}px!important;}
    .pr#{$i * 5} {padding-right:#{$i * 5}px!important;}
    .pb#{$i * 5} {padding-bottom:#{$i * 5}px!important;}
    .pl#{$i * 5} {padding-left:#{$i * 5}px!important;}
}

@for $i from 0 through 20 {
    .mt#{$i * -5} {margin-top:#{$i * -5}px!important;}
    .mr#{$i * -5} {margin-right:#{$i * -5}px!important;}
    .mb#{$i * -5} {margin-bottom:#{$i * -5}px!important;}
    .ml#{$i * -5} {margin-left:#{$i * -5}px!important;}
}

* {
    box-sizing: border-box;
    margin: 0;
}
a {
    color: $link-color;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
br.wbr {
    @include sp {
        display: none;
    }
}
img {
    max-width: 100%;
    height: auto;
    &.mix-darken {
        mix-blend-mode: darken;
    }
}

html {
    scroll-behavior: smooth;
    //メニューオープン時 メニュー以外をスクロールさせないように
    &.scroll-prevent {
        @include sp{
            cursor: pointer;
            /*動き固定*/
            position: fixed;
            /*奥行きを管理*/
            z-index: -1;
            /*下2つで背景を元のサイズのまま表示することができる*/
            width: 100%;
            height: 100%;
        }
    }
}
body {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: $gothic;
}
.container {
    padding: 0 10px;
}
.wrapper {
    width: 100%;
    max-width: 1165px;
    margin: 0 auto;
}
.wrapper-sm {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.align-center {
    text-align: center;
}
.v-middle {
    vertical-align: middle;
}
.ws-pl {
    white-space: pre-line;
}
.negative-indent {
    text-indent: -1em;
    padding-left: 1em;
}
.common-header {
    margin-bottom: 1rem;
    .ja,
    .en {
        display: block;
        line-height: 1.25;
    }
    .ja {
        font-size: 1.75rem;
        font-weight: normal;
        @include sp {
            font-size: 1.25rem;
        }
    }
    .en {
        font-size: 1.5rem;
        color: #ddd;
        letter-spacing: 1px;
        @include sp {
            font-size: 1.25rem;
        }
    }
    &::after {
        content: "";
        display: block;
        width: 60px;
        height: 4px;
        background-color: $main-color;
        margin-top: .5rem;
    }
}
.common-h2 {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.125;
    margin-bottom: 2.5rem;
}
.common-h3 {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 1.125rem;
}
.text-light {
    color: #373A39;
}

.notices {
    margin-bottom: 4rem;
    @include sp {
        margin-bottom: 3rem;
    }
    .notice {
        padding: 2.5rem 3rem;
        border: solid 1px #D8D8D8;
        margin-bottom: 1rem;
        @include sp {
            padding: 1.25rem 1.5rem;
        }
        .info {
            font-size: .75rem;
            margin-bottom: 3rem;
            @include sp {
                margin-bottom: 2rem;
            }
            &.info-top {
                margin-bottom: 1.5rem;
            }
            .tag {
                display: inline-block;
                background-color: #f7f7f7;
                font-weight: bold;
                padding: .1875rem .25rem;
                border: solid 1px #e2e2e2;
                border-radius: 4px;
                &:empty {
                    display: none;
                }
            }
        }
        .notice-title {
            color: inherit;
            line-height: 1.5;
        }
    }
}

.navigation.pagination[role="navigation"] {
    .screen-reader-text {
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }
    .nav-links {
        .page-numbers {
            display: inline-block;
            background-color: #ccc;
            color: white;
            padding: .75rem 1rem;
            text-decoration: none;
            text-shadow: 0 -1px 0 rgba(0,0,0,.125);
            border-radius: 1px;
            margin-bottom: .25rem;
            &.current {
                background-color: #999;
            }
            &:hover,
            &:focus {
                background-color: #B3B3B3;
            }
        }
    }
}

/* wordpress editor -------- */
.editor {
    font-family: $gothic;
    overflow: hidden;
    & > :first-child {
        margin-top: 0;
    }
    .aligncenter {
        display: block;
        margin: 0 auto;
        padding-bottom: 20px;
    }
    .alignright {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
        @include sp {
            float: none;
            padding-left: 0;
            padding-bottom: 0;
        }
    }
    .alignleft {
        float: left;
        padding-right: 20px;
        padding-bottom: 20px;
        @include sp {
            float: none;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
    img[class*="wp-image-"], img[class*="attachment-"] {
        max-width: 100%;
        height: auto;
    }
    a {
        color: $link-color;
        text-decoration: none;
        &:hover {
            color: #69f;
        }
    }
    h1 {
        margin: 50px 0 40px;
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1.25;
        @include sp {
            font-size: 1.25rem;
        }
    }
    h2 {
        margin: 40px 0 30px;
        font-size: 150%;
        font-weight: bold;
        line-height: 1.25;
    }
    h3 {
        margin: 30px 0 20px;
        font-size: 125%;
        font-weight: bold;
        line-height: 1.25;
    }
    p, ul, ol, table {
        font-size: .875rem;
        line-height: 1.875;
    }
    li {
        margin-bottom: 10px;
    }
    ul {
        list-style-type: disc;
        padding-left: 1.5em;
    }
    ol {
        list-style-type: decimal;
        padding-left: 1.5em;
    }
    hr {
        margin: 20px 0;
        border: none;
        border-top: 1px solid #999;
    }
    table {
        width: auto !important;
        height: auto !important;
        border-collapse: collapse;
    }
    th, td {
        padding: 10px 20px;
        border: 1px solid #b5b5b5;
    }
    th {
        background-color: #eee;
    }
    td {
    }
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    del {
        text-decoration: line-through;
    }
}

.pagetop {
    text-align: center;
    margin-bottom: 2rem;
    a {
        display: block;
        @include hover;
    }
}

.col {
    overflow: hidden;
    padding-top: 10px;
    margin-top: -10px;
    .col-4 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        & > * {
            width: calc(100% / 4 - 10px * 2 - 1px);
            max-width: calc(100% / 4 - 10px * 2);
            flex: 1 0 auto;
            margin: 0 10px 20px;
        }
        @include sp {
            & > * {
                width: calc(100% / 2 - 10px * 2 - 1px);
                max-width: calc(100% / 2 - 10px * 2);
                margin: 0 10px 20px;
            }
        }
    }
    .col-3 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        & > * {
            width: calc(100% / 3 - 15px * 2 - 1px);
            max-width: calc(100% / 3 - 15px * 2);
            flex: 1 0 auto;
            margin: 0 15px 20px;
        }
        @include sp {
            margin: 0;
            & > * {
                width: 100%;
                max-width: none;
                margin: 0 0 20px;
            }
        }
    }
    .col-2 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        & > * {
            width: calc(100% / 2 - 15px * 2 - 1px);
            max-width: calc(100% / 2 - 15px * 2);
            flex: 1 0 auto;
            margin: 0 15px 20px;
        }
        @include sp {
            margin: 0;
            & > * {
                width: 100%;
                max-width: none;
                margin: 0 0 20px;
            }
        }
    }
    .col-2m {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        & > * {
            width: calc(100% / 2 - 20px * 2 - 1px);
            max-width: calc(100% / 2 - 20px * 2);
            flex: 1 0 auto;
            margin: 0 20px 20px;
        }
        @include sp {
            margin: 0;
            & > * {
                width: 100%;
                max-width: none;
                margin: 0 0 20px;
            }
        }
    }
}

.btn_wrap{
    .more_btn{
        display: inline-block;
        border: solid 1px #D8D8D8;
        font-size: 0.85rem;
        color:#000;
        padding: 10px 20px;
        text-decoration: none;
        transition: 0.2s;
        margin-top: 1rem;
        &:hover{
            background-color: #888;
            color:white;
        }
    }
    .back_btn{
        display: inline-block;
        border: solid 1px #D8D8D8;
        font-size: 0.85rem;
        color:#000;
        padding: 10px 20px;
        text-decoration: none;
        transition: 0.2s;
        margin-top: 1rem;
        &:hover{
            background-color: #888;
            color:white;
        }
    }
}
