.page-common .sec-content .archive-info {
	.grid-container {
		.grid-main {
			.notices.single{
				.notice{
					border: none;
					padding: 0;
				}
			}
		}
	}
}
