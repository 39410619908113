#footer {
    background-color: #2D2D2D;
    color: #7c7c7c;
    .footer-wrapper {
        max-width: 970px;
        margin-left: auto;
        .links {
            padding: 1.5rem 1.5rem 5rem;
            text-align: right;
            @include sp {
                padding: 2rem 1rem 2rem;
            }
            ul {
                display: inline-block;
                padding-left: 0;
                vertical-align: top;
                @include sp {
                    display: block;
                }
                li {
                    display: inline-block;
                    font-size: .875rem;
                    margin-left: 2rem;
                    margin-top: 10px;
                    vertical-align: top;
                    @include sp {
                        text-align: left;
                        display: block;
                        margin-left: 0;
                        margin-top: 0;
                    }
                    a {
                        color: inherit;
                        text-decoration: none;
                        transition: color .25s;
                        &:hover,
                        &:focus {
                            color: white;
                        }
                        @include sp {
                            display: block;
                            padding: 10px 0;
                        }
                    }
                    ul{
                        li{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .o_links{
            padding: 1.5rem;
            padding-bottom: 0;
            text-align: right;
            @include sp{
                text-align: left;
                padding: 2rem 1rem 0;
            }
            ul{
                display: block;
                padding-left: 0;
                li{
                    display: inline-block;
                    a {
                        display: block;
                        text-decoration: none;
                        transition: all .25s;
                        font-size: .875rem;
                        border:1px solid #999;
                        color: #999;
                        border-radius: 4px;
                        padding:10px;
                        &:hover,
                        &:focus {
                            background-color: rgba(255,255,255,0.5);
                            border:1px solid rgba(255,255,255,0.5);
                            color: #fff;
                        }
                        i{
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .copyright {
            font-size: .75rem;
            padding: .5rem 1.5rem 3rem;
            border-top: solid 1px #7c7c7c;
            text-align: right;
            @include sp {
                font-size: .875rem;
                text-align: left;
                padding: .5rem 1rem 1.5rem;
            }
            .warning {
                margin-bottom: 3rem;
                @include sp {
                    margin-bottom: 1.5rem;
                }
            }
            .copy {
                font-style: italic;
            }
        }
    }
}
