.page-common .sec-content .page-donation {
	.sec-donators {
		margin-top: 5rem;
		.donators {
			list-style: none;
			padding-left: 0;
			li {
				padding: 2rem 0;
				border-bottom: dashed 1px #979797;
				&:first-of-type {
					border-top: dashed 1px #979797;
				}
			}
		}
	}
}
