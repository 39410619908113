#page_service{
    .visual{
        img{
            width: 100%;
        }
    }
    .sales{
        background-position: top left;
        background-size:100% auto;
        background-repeat: no-repeat;
        position: relative;
        min-height: 756px;
        @include sp{
            min-height: 0;
        }
        .sales_list_content{
            max-width: 620px;
            position: absolute;
            top:-72px;
            @include sp{
                margin-top: 10px;
                position: static;
            }
            ol{

                list-style:none;
                font-size: 20px;
                color: #fff;
                margin: 0 25px;
                @include sp{
                    margin: 0 10px;
                    font-size: 1.8rem;
                }
                li{
                    background-color:#E74897;
                    border:6px solid #fff;
                    border-radius: 13px;
                    padding: 12px 25px;
                    margin-bottom: 5px;
                    font-weight: bold;
                    line-height: 1.5;
                    @include sp{
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    &:last-child{
                        background-color: #F5A623;
                    }
                    .list_box01{
                        margin-top: 10px;
                        padding: 0 28px;
                        @include sp{
                            padding: 0;
                        }
                        .fl_b{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            @include sp{
                                display: block;
                            }
                            .col1{
                                text-align: center;
                                p{
                                    background-color:#fff;
                                    color: #000;
                                    font-weight: bold;
                                    font-size: 18px;
                                    padding: 10px 20px;
                                    margin-bottom: 5px;
                                    @include sp{
                                        font-size: 1.6rem;
                                    }
                                }
                            }
                            .col2{
                                p{
                                    font-size: 22px;
                                    @include sp{
                                        text-align: center;
                                        br{
                                            display: none;
                                        }
                                    }
                                    span.big{
                                        font-size: 40px;
                                    }
                                }
                            }
                        }
                        .notice{
                            padding: 20px 0;
                            font-size: 16px;
                            color:#000;
                            @include sp{
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .section01{
        background: #BC2E29;
        color:#fff;
        position: relative;
        text-align: center;
        font-size: 44px;
        font-weight: bold;
        height: 190px;
        padding-top: 50px;
        line-height: 1;
        box-sizing: border-box;
        @include sp{
            font-size: 2.5rem;
            height: auto;
            padding: 20px;
            padding-bottom: 40px;
        }
        .content_svg{
            fill: #ffffff;
        }
    }
    .section02{
        text-align: center;
        color: #fff;
        font-size: 35px;
        font-weight: bold;
        background: #F5A623;
        border-radius: 50px;
        padding: 20px 0;
        margin-top: 35px;
        @include sp{
            font-size: 2.5rem;
            padding: 20px;
        }
    }
    .section03{
        margin-top: 33px;
        .fw-b{
            font-weight: bold;
        }
        table{
            width: 100%;
            border-collapse: separate;
            border-spacing: 10px;
            @include sp{
                display: block;
                tbody{
                    display: block;
                }
            }
            tr{
                @include sp{
                    display: block;
                    border: 4px solid #DCDCDC;
                    margin-top: 20px;
                }
            }
            th{
                border-radius:8px;
                background: #F4F4F4;
                border: 4px solid #DCDCDC;
                width: 343px;
                font-size: 24px;
                color: #000000;
                padding: 15px 30px;
                padding-right: 10px;
                @include sp{
                    display: block;
                    width: auto;
                    border:none;
                    padding: 20px 10px;
                    font-size: 1.8rem;
                    border-radius: 0;
                }
                &:after {
                    content: "任意";
                    float:right;
                    display: block;
                    background-color:#4A4A4A;
                    color:#fff;
                    font-size: 16px;
                    text-align: center;
                    border-radius: 6px;
                    padding: 3px 5px;
                }
                &.hissu:after{
                    content:"必須";
                    background-color:#D43F3A;
                }
                .form_notice{
                    display: none;
                    @include sp{
                        display: inline-block;
                        font-size: 1.2rem;
                        color:#225085;
                        margin-left: 10px;
                    }
                }
            }
            td{
                border-radius:8px;
                border: 4px solid #DCDCDC;
                padding: 0;
                @include sp{
                    display: block;
                    border:none;
                    padding: 20px 10px;
                }
                .select__wrapper{
                    text-align: center;
                    position: relative;
                    border: 1px solid #bbbbbb;
                    border-radius: 2px;
                    background: #ffffff;

                    &.no_border{
                        width:100%;
                        margin:0;
                        border:none;
                        select{
                            text-indent: 30px;
                        }
                    }

                    &:before{
                        position: absolute;
                        top: 0;
                        bottom:0;
                        margin: auto;
                        right: 20px;
                        width: 0;
                        height: 0;
                        padding: 0;
                        content: '';
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 6px solid #666666;
                        pointer-events: none;
                    }
                    select{
                        width: 100%;
                        cursor: pointer;
                        text-indent: 10px;
                        text-overflow: ellipsis;
                        border: none;
                        outline: none;
                        background: transparent;
                        background-image: none;
                        box-shadow: none;
                        -webkit-appearance: none;
                        appearance: none;
                        color: #000;
                        font-size: 16px;
                        margin: 0;
                        &:hover{
                            opacity:0.7;
                        }
                        &::-ms-expand {
                            display: none;
                        }
                    }
                }
                .input__wrapper{
                    border: 1px solid #bbbbbb;
                    border-radius: 2px;
                    background: #ffffff;
                    input{
                        width: 100%;
                        border: none;
                        outline: none;
                        color: #000;
                        font-size: 16px;
                        margin: 0;
                    }
                    &.no_border{
                        width:100%;
                        margin:0;
                        border:none;
                        input{
                            text-indent: 30px;
                        }
                    }
                }
                .radio__wrapper{
                    input[type="radio"]:checked {
                        background-color: #fff;
                    }
                    input[type="radio"]:checked:before{
                        background-color: #000;
                    }
                }
                .textarea__wrapper{
                    padding: 25px 40px;
                    @include sp{
                        padding: 0;
                    }
                    textarea{
                        box-sizing: border-box;
                        width: 100%;
                        margin: 0;
                    }
                }
                .form_notice{
                    font-size: 14px;
                    color:#225085;
                    @include sp{
                        display: none;
                    }
                }
                .fl_box{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }

                .form_box{
                    position: relative;
                    .error_box{
                        position: absolute;
                        display: inline-block;
                        top:-30px;
                        left:40px;
                        background-color:#fff;
                        border:1px solid red;
                        padding: 5px 10px;
                        display: none;
                        &.show{
                            display: block;
                        }
                        @include sp{
                            left:0;
                            box-shadow:0px 2px 9px -1px #bfbfbf;
                        }
                        .error{
                            position: relative;
                            color:red;
                            font-size: 14px;
                            font-weight: bold;
                            white-space: nowrap;
                            &:before{
                                content: '';
                                display: block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 11px 7px 0px 7px;
                                position: absolute;
                                margin: auto;
                                left: 0;
                                right:0;
                                bottom:-16px;
                                border-color: red transparent;
                                @include sp{
                                    display: none;
                                }
                            }
                            &:after {
                                border-width: 10px 6px 0 6px;
                                content: '';
                                display: block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-color: #fff transparent transparent transparent;
                                position: absolute;
                                margin: auto;
                                bottom:-15px;
                                left: 0;
                                right:0;
                                @include sp{
                                    display: none;
                                }

                            }
                        }
                    }
                }

                

                /* 丁目・番地  お名前 フリガナ*/
                &.table_style1{
                    padding:0 20px;
                    @include sp{
                        padding: 20px 10px;
                    }
                    .fl_box{
                        @include sp{
                            display: block;
                        }
                    }
                    label{
                        display: block;
                        padding:0 20px;
                        @include sp{
                            padding: 10px;
                            margin-top: 10px;
                        }
                    }
                    .select__wrapper,.input__wrapper{
                        width: 35%;
                        @include sp{
                            width: auto;
                        }
                    }

                }
                /* 建物面積 */
                &.menseki{
                    padding:0 20px;
                    @include sp{
                        padding: 20px 10px;
                    }
                    .input__wrapper{
                        margin:0 20px;
                        width: 35%;
                        @include sp{
                            margin: 0;
                            margin: 5px;
                            width: auto;
                        }
                    }
                    .radio__wrapper{
                        margin-right: 20px;
                        @include sp{
                            margin-right: 5px;
                        }
                    }
                }
                /* 築年数 */
                &.tikunensu{
                    padding:0 20px;
                    @include sp{
                        padding: 20px 10px;
                    }
                    .select__wrapper,.input__wrapper{
                        margin:0 20px;
                        width: 35%;
                        @include sp{
                            width: 100%;
                        }
                    }
                }
                /* 間取り */
                &.madori{
                    padding:0 20px;
                    @include sp{
                        padding: 20px 10px;
                    }
                    .select__wrapper,.input__wrapper{
                        margin:0 20px;
                        width: 35%;
                        @include sp{
                            width: 100%;
                        }
                    }
                }
                /* ラジオボタンのみ */
                &.only_raio_box{
                    padding: 0 20px;
                    @include sp{
                        padding: 20px 10px;
                    }
                    .radio__wrapper{
                        padding:0 20px;
                        @include sp{
                            margin-bottom: 10px;
                            display: block;
                        }
                    }
                }
            }
        }
        .allow{
            height: 200px;
            position: relative;
            &:before{
                content:"";
                display: block;
                position: absolute;
                top:0;
                bottom:0;
                right:0;
                left:0;
                width: 0;
                height: 0;
                margin: auto;
                border-style: solid;
                border-width: 60px 133px 0 133px;
                border-color: #bc2e29 transparent transparent transparent;
            }
        }
        .submit_btn_box{
            button{
                width: 100%;
                text-align: center;
                color:#fff;
                background-color: #D0021B;
                border-radius: 50px;
                font-size: 44px;
                font-weight: bold;
                padding: 17px 0;
                @include sp{
                    font-size: 3rem;
                    padding: 17px;
                }
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

