.page-common .sec-content .page-producers {
	.producers-wrapper {
		overflow: auto;
	}
	table.producers {
		border-collapse: collapse;
		font-size: .875rem;
		line-height: 1.25;
		margin-top: 1rem;
		thead {
			th {
				background-color: #F4F3EE;
				font-weight: normal;
			}

			th.mark {
				font-size: .75rem;
				box-sizing: content-box;
				width: 2em;
				max-width: 2em;
				line-height: 1.125;
				span {
					display: inline-block;
				}
			}
		}
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: #eee;
				}
				td {
					padding: .5rem;
					@include sp {
						padding: .5rem .25rem;
					}
					&:empty {
						&::before {
							content: "-";
							color: #bbb;
						}
					}
					&:first-of-type {
						text-align: center;
					}
					&:nth-of-type(-n + 4) {
						white-space: nowrap;
					}
					&:nth-of-type(n + 6) {
						text-align: center;
					}
					&:nth-of-type(2) {
						a {
							&::before {
								content: "";
								display: inline-block;
								width: 1em;
								height: 1em;
								background-image: url(../images/icon_pdf.jpg);
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;
								vertical-align: -.125em;
								mix-blend-mode: darken;
								@include hdpi(2) {
									background-image: url(../images/icon_pdf@2x.jpg);
								}
							}
						}
					}
					&:nth-of-type(3) {
						@include sp {
							box-sizing: content-box;
							min-width: 2em;
							white-space: normal;
						}
					}
					&:nth-of-type(4) {
						@include sp {
							box-sizing: content-box;
							min-width: 3em;
							white-space: normal;
						}
					}
				}
			}
		}
	}
}
