.page-common {
	.sec-header {
		background-color: #F6F8FA;
		padding: 4rem 0 1rem;
		@include sp {
			padding-top: 2.5rem;
		}
	}
	.sec-content {
		padding: 3.5rem 0 10rem;
		.grid-container {
			display: grid;
			grid-gap: 40px;
			grid-template:
				"main side" auto
				/1fr  340px;
			@include sp {
				display: block;
			}
			.grid-main {
				grid-area: main;
				overflow-x: auto;
			}
			.grid-side {
				grid-area: side;
				@include sp {
					margin-top: 5em;
				}
				dl.side-contents {
					.side-content {
						margin-bottom: 5rem;
						& > dt {
							margin-bottom: 1.5rem;
							&::after {
								content: "";
								display: block;
								height: 2px;
								background-image: linear-gradient(to right, $main-color 60px, #DEDDDE 60px);
								background-size: 100% auto;
								background-repeat: no-repeat;
								background-position: center;
								margin-top: .5rem;
							}
						}
					}
				}
			}
		}
		section {
			margin-bottom: 4rem;
			&.mt-sec {
				margin-top: 4rem;
			}
		}
		.text {
			line-height: 1.5;
			font-size: .875rem;
			& + .text {
				margin-top: 2.5em;
			}
		}
		.data-list {
			list-style: none;
			padding-left: 0;
			li {
				margin-bottom: 2.5rem;
				@include sp {
					margin-bottom: 1.5rem;
				}
				a {
					display: flex;
					align-items: center;
					height: 100%;
					position: relative;
					color: inherit;
					line-height: 1.25;
					text-decoration: none;
					padding: 1.25rem 1rem 1.25rem 4.5rem;
					border: solid 1px #E9EFF4;
					border-radius: 5px;
					&::before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						left: 1.5rem;
						margin: auto;
						width: 28px;
						height: 31px;
						background-image: url(../images/icon_pdf.jpg);
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						@include hdpi(2) {
							background-image: url(../images/icon_pdf@2x.jpg);
						}
					}
				}
			}
			&.data-list-file {
				li a::before {
					background-image: url(../images/icon_file.jpg);
					@include hdpi(2) {
						background-image: url(../images/icon_file@2x.jpg);
					}
				}
			}
		}
	}
}
