.page-common .sec-content .page-history {
	.sec-visual {
		margin-bottom: 5rem;
		.visual-wrapper {
			position: relative;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(../images/history/visual.jpg);
			padding-top: 55.641025641025641%;
			@include hdpi(2) {
				background-image: url(../images/history/visual@2x.jpg);
			}
			@include sp {
				background: #FBBA40;
				padding: 1.5rem 1rem;
				border-radius: 5px;
			}
			dl.periods {
				height: 0;
				@include sp {
					height: auto;
				}
				dt {
					position: absolute;
					font-size: 1.875rem;
					font-weight: bold;
					white-space: nowrap;
					@media screen and (max-width: 1000px) {
						font-size: 1.5rem;
					}
					@include sp {
						position: static;
						font-size: 1.25rem;
						white-space: normal;
						margin-bottom: 1rem;
					}
				}
				dd {
					position: absolute;
					top: 48%;
					width: 14.615384615384615%;
					@include sp {
						position: static;
						width: auto;
					}
					ol.eras {
						list-style: none;
						padding-left: 0;
						li {
							margin-bottom: 5px;
							a {
								position: relative;
								display: block;
								background-color: white;
								color: inherit;
								font-size: .8125rem;
								font-weight: bold;
								padding: .75em 1.375em .75em 1.25em;
								border-radius: 1000px;
								@media screen and (max-width: 1000px) {
									font-size: .75rem;
								}
								@include sp {
									font-size: 1rem;
								}
								&::after {
									content: "↓";
									display: inline-block;
									position: absolute;
									top: 0;
									right: .625em;
									bottom: 0;
									margin: auto;
									height: 1em;
									line-height: 1;
								}
							}
						}
					}
				}
				.ancient,
				.middle {
					@include sp {
						margin-bottom: 2rem;
					}
				}
				.ancient,
				.middle,
				.modern {
					dt {
						@include sp {
							&::before {
								content: "\00a0";
								display: inline-block;
								width: 1em;
								background-size: 100% auto;
								background-repeat: no-repeat;
								background-position: center;
								margin-right: .25em;
							}
						}
					}
				}
				.ancient {
					dt {
						top: 25%;
						right: 74%;
						@include sp {
							&::before {
								background-image: url(../images/history/icon_ancient.png);
								@include hdpi(2) {
									background-image: url(../images/history/icon_ancient@2x.png);
								}
							}
						}
					}
					dd {
						left: 18.75%;
					}
				}
				.middle {
					dt {
						bottom: 15%;
						left: 47%;
						@include sp {
							&::before {
								background-image: url(../images/history/icon_middle.png);
								@include hdpi(2) {
									background-image: url(../images/history/icon_middle@2x.png);
								}
							}
						}
					}
					dd {
						left: 40%;
					}
				}
				.modern {
					dt {
						top: 23%;
						right: 32%;
						@include sp {
							&::before {
								background-image: url(../images/history/icon_modern.png);
								@include hdpi(2) {
									background-image: url(../images/history/icon_modern@2x.png);
								}
							}
						}
					}
					dd {
						left: 61.25%;
					}
				}
			}
		}
	}
	.sec-periods {
		section {
			margin-bottom: 9rem;
			@include sp {
				margin-bottom: 5rem;
			}
			&.era9 {
				margin-top: -3rem;
				.period-header {
					padding-top: 3rem;
					margin-bottom: 4rem;
				}
			}
		}
		.text {
			line-height: 2;
			@include sp {
				text-align: left;
				br {
					display: none;
				}
			}
		}
		.period-header {
			color: #FBBA40;
			font-size: 2.25rem;
			font-weight: normal;
			margin-bottom: 1rem;
			@include sp {
				font-size: 1.75rem;
			}
		}
		.flex-wrapper {
			display: flex;
			@include sp {
				flex-direction: column;
			}
			.flex-left,
			.flex-right {
				flex: 1 1 0%;
				@include sp {
					order: 2;
					text-align: center;
					img {
						//min-width: 100%;
					}
				}
				&.flex-none {
					flex: none;
					@include sp {
						order: 1;
						margin-bottom: 2rem;
					}
				}
			}
			.flex-left {
				margin-right: 40px;
				@include sp {
					margin-right: 0;
				}
			}
			.flex-right {
			}
		}
		dl.eras {
			.era {
				margin-bottom: 1rem;
				dt {
					position: relative;
					z-index: 1;
					font-size: 1.3125rem;
					font-weight: bold;
					padding-top: 3rem;
					padding-bottom: 3rem;
					span {
						display: inline-block;
						background-color: white;
						padding-right: 2em;
						@include sp {
							padding-right: 1em;
						}
					}
					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: -1;
						margin: auto;
						height: 1px;
						background-color: #979797;
					}
				}
				dd {
					.era-supplement {
						background-color: #F4F3EE;
						padding: 3rem 4rem;
						margin-top: 4rem;
						@include sp {
							padding: 2rem 1rem;
						}
						.era-supplement-header {
							font-size: 1.25rem;
							font-weight: bold;
							margin-bottom: 3rem;
							@include sp {
								margin-bottom: 2rem;
							}
						}
						&.era-supplement-era3 {
							.era-supplement-header {
								margin-bottom: 0;
								@include sp {
									margin-bottom: 2rem;
								}
							}
							.align-center {
								margin-bottom: 2rem;
							}
						}
					}
				}
			}
		}
	}
}
