/*
Theme Name: Luminous Child
Template:   dp-luminous-business
*/
/* mixinsの読み込み */
@import "mixins/include.scss";
/* variablesの読み込み */
@import "_variables.scss";

/* lib */
@import "lib/fontawesome/_fontawesome.scss";
@import "lib/fontawesome/_solid.scss";
@import "lib/fontawesome/_v4-shims.scss";

/* common */
@import "_common.scss";

/* parts */
@import "parts/_header.scss";
@import "parts/_footer.scss";

/* page */
@import "page/_service.scss";
@import "page/_index.scss";
@import "page/_page-common.scss";
@import "page/_page-donation.scss";
@import "page/_page-member.scss";
@import "page/_page-access.scss";
@import "page/_page-lack.scss";
@import "page/_page-history.scss";
@import "page/_page-traditional-crafts.scss";
@import "page/_page-law.scss";
@import "page/_page-current-situtation.scss";
@import "page/_page-producers.scss";
@import "page/_single-hall.scss";
@import "page/_single-info.scss";
@import "page/_archive-info.scss";
@import "page/_archive-hall.scss";

