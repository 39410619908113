@mixin hover($opacity:0.7) {
    transition:opacity .2s;
    &:hover{
        filter: alpha(opacity=$opacity*100);
        -moz-opacity:$opacity;
        opacity:$opacity;
    }
}

@mixin hover-scale($scale:1.05) {
    img{
        transition: transform .75s cubic-bezier(.34, .1, .43, .95);
    }
    &:hover{
        img{
            transform: scale($scale);
        }
    }
}

@mixin hover-shadow() {
    transition: box-shadow .4s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);
    &:hover{
       box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    }
}