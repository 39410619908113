#header {
    line-height: 1.5;
    padding: 1.25rem 0;
    @include sp {
        padding: 1rem 0;
    }
    .flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .flex-left {
            padding-top: 1.25rem;
            display: flex;
            @include sp {
                padding-top: 0;
                align-items: center;
            }
            .logo {
                flex: none;
                max-width: 60px;
                margin-right: 1rem;
                @include sp {
                    max-width: 27px;
                    margin-right: .75rem;
                }
                a {
                    img {
                        @include hover;
                        display: block;
                    }
                }
            }
            .title {
                flex: 1 1 auto;
                h1 {
                    margin-top: .75rem;
                    @include sp {
                        max-width: 240px;
                        margin-top:0;
                        line-height: 1;
                    }
                }
                address {
                    font-size: .875rem;
                    font-style: normal;
                    @include sp {
                        font-size: .65rem;
                        line-height: 1.25;
                        white-space: nowrap
                    }
                }
            }
        }
        .flex-right {
            position: relative;
            @include sp {
                padding-left: .5rem;
            }
            .a-lang,
            #openMenu {
                vertical-align: middle;
            }
            .a-lang {
                display: inline-block;
                min-width: 80px;
                background-color: black;
                color: white;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                margin-right: 2rem;
                @include hover;
                @include sp {
                    display: none;
                }
            }
            #openMenu {
                display: inline-block;
                background: none;
                padding: 0;
                border: 0;
                cursor: pointer;
                .hamburger {
                    position: relative;
                    width: 2.25rem;
                    height: 3px;
                    background-color: black;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background-color: black;
                    }
                    &::before {
                        top: 10px;
                    }
                    &::after {
                        bottom: 10px;
                    }
                }
            }
            #menu {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2147483647;
                min-width: 20em;
                background-color: black;
                color: white;
                font-size: .875rem;
                padding-bottom: 1rem;
                opacity: 0;
                visibility: hidden;
                transition: opacity .25s, visibility .25s step-end;
                a {
                    pointer-events: none;
                }
                &.is-shown {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity .25s, visibility .25s step-start;
                    a {
                        pointer-events: auto;
                    }
                }
                @include sp {
                    position: fixed;
                    overflow: auto;
                    left: 25%;
                    bottom: 0;
                    min-width: 0;
                    opacity: 1;
                    transform: translateX(100%);
                    transition: transform .25s, visibility .25s step-end;
                    &.is-shown {
                        transform: none;
                        transition: transform .25s, visibility .25s step-start;
                    }
                }
                header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1.125em 1.5em;
                    border-bottom: dashed 1px #8D8888;
                    .menu-title {
                        line-height: 1;
                    }
                    #closeMenu {
                        background: none;
                        color: white;
                        padding: 0;
                        border: none;
                        cursor: pointer;
                        .cross {
                            position: relative;
                            height: 1.5rem;
                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                margin: auto;
                                width: 1rem;
                                height: 3px;
                                background-color: white;
                            }
                            &::before {
                                transform: rotate(45deg);
                            }
                            &::after {
                                transform: rotate(-45deg);
                            }
                        }
                        .label {
                            line-height: 1;
                            font-size: .625rem;
                        }
                    }
                }
                ul {
                    list-style: none;
                    padding-left: 0;
                    li {
                        border-bottom: dashed 1px #8D8888;
                        a {
                            display: block;
                            color: inherit;
                            text-decoration: none;
                            padding: .5625em 1.5em;
                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }
                        ul {
                            li {
                                border-bottom-style: none;
                                a {
                                    padding-left: 3.5em;
                                }
                            }
                        }
                    }
                    .only-sp {
                        display: none;
                        @include sp {
                            display: block;
                        }
                    }
                }
                .login-link{
                    display: none;
                    @include sp {
                        display: block;
                        text-align: center;
                        margin-top: 20px;
                        .btn{
                            display: inline-block;
                            width: auto;
                            background-color: $main-color;
                            color: white;
                            font-size: .875rem;
                            text-align: center;
                            text-decoration: none;
                            line-height: 1.25;
                            padding: 0.75rem;
                            border: none;
                            border-radius: 3px;
                            transition: background-color .25s;
                            &:hover,
                            &:focus {
                                background-color: lighten($main-color, 10%);
                            }
                        }
                    }
                }
            }
        }
    }
}
