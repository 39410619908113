.page-index {
    .sec {
        padding-top: 4rem;
        padding-bottom: 4rem;
        width: 100%;
        @include sp{
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 4rem;
            margin-bottom: 4rem;
            width: 100%;
        }
    }
    .sec-login {
        min-height: 510px;
        background-image: url(../images/index/visual.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        @include hdpi(2) {
            background-image: url(../images/index/visual@2x.jpg);
        }
        @include sp {
            min-height: 35.318559556vw;
            //position: relative;
            margin-top: 0;
            margin-bottom: 0;
        }
        .login-link {
            padding-top: 3.375rem;
            margin-left: auto;
            max-width: 260px;
            color: white;
            @include sp {
                display: none;
                position: absolute;
                top: calc(50% - 50px);
                left: 0;
                right: 0;
                max-width: none;
                text-align: center;
                padding: 0 1rem;
            }
            h3 {
                font-size: 0.875rem;
                font-weight: normal;
                text-align: center;
                margin-bottom: .875rem;
                @include sp {
                    margin-bottom: .5rem;
                }
                img {
                    @include sp {
                        filter: drop-shadow(0 1px 2px rgba(0, 71, 176, 0.5));
                    }
                }
                span {
                    @include sp {
                        text-shadow: 0 1px 2px rgba(0, 71, 176, 0.625);
                    }
                }
            }
            a.btn {
                display: block;
                width: 100%;
                background-color: $main-color;
                color: white;
                font-size: .875rem;
                text-align: center;
                text-decoration: none;
                line-height: 1.25;
                padding: 1.25rem;
                border: none;
                border-radius: 3px;
                transition: background-color .25s;
                &:hover,
                &:focus {
                    background-color: lighten($main-color, 10%);
                }
                @include sp {
                    display: inline-block;
                    width: auto;
                    padding: 1rem;
                }
            }
        }
    }
    .sec-about {
        dl {
            display: flex;
            @include sp {
                display: block;
            }
            dt {
                flex: none;
                margin-right: 5rem;
                @include sp {
                    margin-right: 0;
                }
            }
            dd {
                font-size: .875rem;
                padding-top: .5rem;
                p {
                    line-height: 1.75;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .sec-symbol {
        background-color: #F6F8FA;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        h2 {
            font-size: 1.5rem;
            font-weight: normal;
            margin-bottom: 2rem;
            @include sp {
                font-size: 1.25rem;
            }
        }
        .flex-container {
            display: flex;
            .only_sp{
                display: none;
                @include screen(0,425px){
                    display: block;
                    text-align: center;
                    padding-bottom: 20px;
                    img{
                        max-width: 100px;
                    }
                }
            }
            .flex-left{
                width: 55%;
                @include sp {
                    width: 70%;
                }
                @include screen(0,425px){
                    width: auto;
                }
            }
            .flex-right {
                width: 45%;
                @include sp {
                    padding-left: 2rem;
                    padding-top: 52px;
                    width: 30%;
                    figcaption{
                        display: none;
                    }
                }
                @include screen(0,425px){
                    display: none;
                }
            }
            .flex-left {
                section {
                    font-size: .875rem;
                    line-height: 1.75;
                    h3 {
                        color: #919D8E;
                        font-size: 2.625rem;
                        font-family: $mincho;
                        font-weight: normal;
                        line-height: 1;
                        margin-bottom: 1rem;
                        @include sp {
                            font-size: 1.85rem;
                            line-height: 1.25;
                            white-space: nowrap;
                        }
                    }
                    ul{
                        padding-left: 20px;
                        margin-top: 10px;
                        li {
                            margin-bottom: .75rem;
                            @include sp {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                    p:last-of-type {
                        margin-top: 1rem;
                    }
                }
            }
            .flex-right {
                figure {
                    text-align: center;
                }
                figcaption {
                    font-size: 1.125rem;
                    margin-top: 1.75rem;
                }
            }
        }
    }
    .sec-notice {
        .sec-title {
            font-size: 2.125rem;
            font-weight: normal;
            text-align: center;
            margin-bottom: 4rem;
            @include sp {
                font-size: 1.5rem;
                margin-bottom: 2rem;
            }
        }
        .notices{
            margin-bottom: 2rem;
            .notice{
                padding: 0;
                border:none;
                margin-bottom: 1.25rem;
                .info-top{
                    margin-bottom: .75rem;
                    @include sp{
                        margin-bottom: .5rem;
                    }
                    .notice-title{
                        font-size: 1rem;
                        display: inline-block;
                        padding: 0 10px;
                    }
                }
            }
        }
        .btn_wrap{
            text-align: center;
        }
    }
}
