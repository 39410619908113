.page-common .sec-content .page-traditional-crafts {
	.text {
		line-height: 1.5;
		& + .text {
			margin-top: 1.75em;
		}
	}
	dl.pdfs {
		margin-top: 2rem;
		dt {
			font-size: 1.125rem;
			font-weight: bold;
			margin-bottom: 2rem;
		}
	}
}
