.page-common .sec-content .single-hall {
	.single-hall-header {
		color: $main-color;
		font-size: 1.25rem;
		font-weight: normal;
		margin-bottom: 1.75rem;
	}
	.grid-container {
		.grid-main {
			.main_title_wrap{
				display: flex;
				margin-bottom: 4rem;
				@include sp{
					//display: block;
				}
				.main_title{
					flex-grow: 2;
					margin-bottom: 0;
				}
				.main_title_img{
					padding-right: 20px;
					align-self: center;
					@include sp{
						display: block;
						padding-right: 10px;
						text-align: center;
					}
					img{
						max-height: 80px;
						width: auto;
						@include sp{
							//max-height: 40px;
						}
					}
				}
			}
			.title_sub {
				font-size: .875rem;
				margin-bottom: .5rem;
			}
			.main_title {
				font-size: 1.5rem;
				font-weight: normal;
				line-height: 1.25;
				border-color: black;
				border-style: dashed none;
				border-width: 1px;
				padding: 1.5rem 0;
				margin-bottom: 4rem;
			}
			.hall_table {
				width: 100%;
				border-collapse: collapse;
				font-size: .875rem;
				margin-bottom: 3rem;
				th, td {
					border: solid 1px #DEDDDE;
					text-align: left;
					line-height: 1.75;
				}
				th {
					width: 194px;
					background-color: #ECECED;
					font-weight: normal;
					padding: .75rem;
				}
				td {
					padding: .75rem 1.5rem;
				}
				//主な展示品
				tr.exhibits{
					ul{
						margin-top: 5px;
						padding-left: 0;
						li{
							display: inline-block;
							margin: 0 5px;
							img{
								max-width: 100px;
							}
						}
					}
				}
				@include sp {
					display: block;
					tbody, tr, th, td {
						display: block;
						width: auto;
					}
					tbody {
						th, td {
							border-bottom-style: none;
							&:empty {
								&::before {
									content: "\00a0";
								}
							}
						}
						tr:last-of-type > :last-child {
							border-bottom-style: solid;
						}

					}
				}
			}
			.hall_title {
				font-size: 1.125rem;
				font-weight: normal;
				margin-bottom: 1.5rem;
			}
			.hall_text {
				font-size: .875rem;
				line-height: 1.75;
				padding: .75rem 1.5rem;
				border: solid 1px #DEDDDE;
				margin-bottom: 3rem;
			}
			.more_list {
				display: none;
			}
			
		}
		.grid-side {
			dl.halls {
				font-size: .875rem;
				dt, dd {
					margin-bottom: .5rem;
				}
				dt {
					color: $main-color;
					font-weight: normal;
					margin-top: 20px;
				}
				dd {
					ul {
						line-height: 1.75;
						padding-left: 1em;
						li {
							list-style: none;
							a {
								color: inherit;
								text-decoration: none;
								&:hover,
								&:focus {
									text-decoration: underline;
								}
								.nav_content{
									margin: 5px 0;
									display: table;
									width: 100%;
									.img,.title{
										display: table-cell;
										vertical-align: middle;
									}
									.img{
										width: 15%;
										@include hover;
										img{
											vertical-align: bottom;
										}
									}
									.title{
										padding-left: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
