.page-common .sec-content .page-member {
	dl.expl {
		counter-reset: dt;
		& > div {
			overflow: hidden;
			margin-bottom: 2rem;
			&:nth-of-type(2) {
				& > dd p {
					padding-left: 3em;
				}
			}
			& > dt {
				float: left;
				margin-right: 1em;
				counter-increment: dt;
				&::before {
					content: counter(dt) ".";
					display: inline-block;
					min-width: 1em;
				}
			}
			& > dd {
				dl.fee {
					& > div {
						display: inline-flex;
						margin-right: 3em;
						&:last-of-type {
							margin-right: 0;
						}
						dt, dd {
							flex: none;
						}
						dt {
							&::after {
								content: "　･･･　";
							}
						}
					}
				}
			}
		}
	}
}
