@charset "UTF-8";
/*
Theme Name: Luminous Child
Template:   dp-luminous-business
*/
/* mixinsの読み込み */
/* variablesの読み込み */
/* lib */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-key:before {
  content: "\f084"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-print:before {
  content: "\f02f"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-trello:before {
  content: "\f181"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-water:before {
  content: "\f773"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../webfonts/fa-solid-900.eot");
  src: url("../webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.woff") format("woff"), url("../webfonts/fa-solid-900.ttf") format("truetype"), url("../webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

.fa.fa-glass:before {
  content: "\f000"; }

.fa.fa-meetup {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-star-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-star-o:before {
  content: "\f005"; }

.fa.fa-remove:before {
  content: "\f00d"; }

.fa.fa-close:before {
  content: "\f00d"; }

.fa.fa-gear:before {
  content: "\f013"; }

.fa.fa-trash-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-trash-o:before {
  content: "\f2ed"; }

.fa.fa-file-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-o:before {
  content: "\f15b"; }

.fa.fa-clock-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-clock-o:before {
  content: "\f017"; }

.fa.fa-arrow-circle-o-down {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-arrow-circle-o-down:before {
  content: "\f358"; }

.fa.fa-arrow-circle-o-up {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-arrow-circle-o-up:before {
  content: "\f35b"; }

.fa.fa-play-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-play-circle-o:before {
  content: "\f144"; }

.fa.fa-repeat:before {
  content: "\f01e"; }

.fa.fa-rotate-right:before {
  content: "\f01e"; }

.fa.fa-refresh:before {
  content: "\f021"; }

.fa.fa-list-alt {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-dedent:before {
  content: "\f03b"; }

.fa.fa-video-camera:before {
  content: "\f03d"; }

.fa.fa-picture-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-picture-o:before {
  content: "\f03e"; }

.fa.fa-photo {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-photo:before {
  content: "\f03e"; }

.fa.fa-image {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-image:before {
  content: "\f03e"; }

.fa.fa-pencil:before {
  content: "\f303"; }

.fa.fa-map-marker:before {
  content: "\f3c5"; }

.fa.fa-pencil-square-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-pencil-square-o:before {
  content: "\f044"; }

.fa.fa-share-square-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-share-square-o:before {
  content: "\f14d"; }

.fa.fa-check-square-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-check-square-o:before {
  content: "\f14a"; }

.fa.fa-arrows:before {
  content: "\f0b2"; }

.fa.fa-times-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-times-circle-o:before {
  content: "\f057"; }

.fa.fa-check-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-check-circle-o:before {
  content: "\f058"; }

.fa.fa-mail-forward:before {
  content: "\f064"; }

.fa.fa-eye {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-eye-slash {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-warning:before {
  content: "\f071"; }

.fa.fa-calendar:before {
  content: "\f073"; }

.fa.fa-arrows-v:before {
  content: "\f338"; }

.fa.fa-arrows-h:before {
  content: "\f337"; }

.fa.fa-bar-chart {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-bar-chart:before {
  content: "\f080"; }

.fa.fa-bar-chart-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-bar-chart-o:before {
  content: "\f080"; }

.fa.fa-twitter-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-facebook-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gears:before {
  content: "\f085"; }

.fa.fa-thumbs-o-up {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-thumbs-o-up:before {
  content: "\f164"; }

.fa.fa-thumbs-o-down {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-thumbs-o-down:before {
  content: "\f165"; }

.fa.fa-heart-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-heart-o:before {
  content: "\f004"; }

.fa.fa-sign-out:before {
  content: "\f2f5"; }

.fa.fa-linkedin-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-linkedin-square:before {
  content: "\f08c"; }

.fa.fa-thumb-tack:before {
  content: "\f08d"; }

.fa.fa-external-link:before {
  content: "\f35d"; }

.fa.fa-sign-in:before {
  content: "\f2f6"; }

.fa.fa-github-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-lemon-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-lemon-o:before {
  content: "\f094"; }

.fa.fa-square-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-square-o:before {
  content: "\f0c8"; }

.fa.fa-bookmark-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-bookmark-o:before {
  content: "\f02e"; }

.fa.fa-twitter {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-facebook {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-facebook:before {
  content: "\f39e"; }

.fa.fa-facebook-f {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-facebook-f:before {
  content: "\f39e"; }

.fa.fa-github {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-credit-card {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-feed:before {
  content: "\f09e"; }

.fa.fa-hdd-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hdd-o:before {
  content: "\f0a0"; }

.fa.fa-hand-o-right {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-o-right:before {
  content: "\f0a4"; }

.fa.fa-hand-o-left {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-o-left:before {
  content: "\f0a5"; }

.fa.fa-hand-o-up {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-o-up:before {
  content: "\f0a6"; }

.fa.fa-hand-o-down {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-o-down:before {
  content: "\f0a7"; }

.fa.fa-arrows-alt:before {
  content: "\f31e"; }

.fa.fa-group:before {
  content: "\f0c0"; }

.fa.fa-chain:before {
  content: "\f0c1"; }

.fa.fa-scissors:before {
  content: "\f0c4"; }

.fa.fa-files-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-files-o:before {
  content: "\f0c5"; }

.fa.fa-floppy-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-floppy-o:before {
  content: "\f0c7"; }

.fa.fa-navicon:before {
  content: "\f0c9"; }

.fa.fa-reorder:before {
  content: "\f0c9"; }

.fa.fa-pinterest {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-pinterest-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-plus-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-plus {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-plus:before {
  content: "\f0d5"; }

.fa.fa-money {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-money:before {
  content: "\f3d1"; }

.fa.fa-unsorted:before {
  content: "\f0dc"; }

.fa.fa-sort-desc:before {
  content: "\f0dd"; }

.fa.fa-sort-asc:before {
  content: "\f0de"; }

.fa.fa-linkedin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-linkedin:before {
  content: "\f0e1"; }

.fa.fa-rotate-left:before {
  content: "\f0e2"; }

.fa.fa-legal:before {
  content: "\f0e3"; }

.fa.fa-tachometer:before {
  content: "\f3fd"; }

.fa.fa-dashboard:before {
  content: "\f3fd"; }

.fa.fa-comment-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-comment-o:before {
  content: "\f075"; }

.fa.fa-comments-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-comments-o:before {
  content: "\f086"; }

.fa.fa-flash:before {
  content: "\f0e7"; }

.fa.fa-clipboard {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-paste {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-paste:before {
  content: "\f328"; }

.fa.fa-lightbulb-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-lightbulb-o:before {
  content: "\f0eb"; }

.fa.fa-exchange:before {
  content: "\f362"; }

.fa.fa-cloud-download:before {
  content: "\f381"; }

.fa.fa-cloud-upload:before {
  content: "\f382"; }

.fa.fa-bell-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-bell-o:before {
  content: "\f0f3"; }

.fa.fa-cutlery:before {
  content: "\f2e7"; }

.fa.fa-file-text-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-text-o:before {
  content: "\f15c"; }

.fa.fa-building-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-building-o:before {
  content: "\f1ad"; }

.fa.fa-hospital-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hospital-o:before {
  content: "\f0f8"; }

.fa.fa-tablet:before {
  content: "\f3fa"; }

.fa.fa-mobile:before {
  content: "\f3cd"; }

.fa.fa-mobile-phone:before {
  content: "\f3cd"; }

.fa.fa-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-circle-o:before {
  content: "\f111"; }

.fa.fa-mail-reply:before {
  content: "\f3e5"; }

.fa.fa-github-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-folder-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-folder-o:before {
  content: "\f07b"; }

.fa.fa-folder-open-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-folder-open-o:before {
  content: "\f07c"; }

.fa.fa-smile-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-smile-o:before {
  content: "\f118"; }

.fa.fa-frown-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-frown-o:before {
  content: "\f119"; }

.fa.fa-meh-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-meh-o:before {
  content: "\f11a"; }

.fa.fa-keyboard-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-keyboard-o:before {
  content: "\f11c"; }

.fa.fa-flag-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-flag-o:before {
  content: "\f024"; }

.fa.fa-mail-reply-all:before {
  content: "\f122"; }

.fa.fa-star-half-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-star-half-o:before {
  content: "\f089"; }

.fa.fa-star-half-empty {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-star-half-empty:before {
  content: "\f089"; }

.fa.fa-star-half-full {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-star-half-full:before {
  content: "\f089"; }

.fa.fa-code-fork:before {
  content: "\f126"; }

.fa.fa-chain-broken:before {
  content: "\f127"; }

.fa.fa-shield:before {
  content: "\f3ed"; }

.fa.fa-calendar-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-calendar-o:before {
  content: "\f133"; }

.fa.fa-maxcdn {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-html5 {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-css3 {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ticket:before {
  content: "\f3ff"; }

.fa.fa-minus-square-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-minus-square-o:before {
  content: "\f146"; }

.fa.fa-level-up:before {
  content: "\f3bf"; }

.fa.fa-level-down:before {
  content: "\f3be"; }

.fa.fa-pencil-square:before {
  content: "\f14b"; }

.fa.fa-external-link-square:before {
  content: "\f360"; }

.fa.fa-compass {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-caret-square-o-down {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-caret-square-o-down:before {
  content: "\f150"; }

.fa.fa-toggle-down {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-toggle-down:before {
  content: "\f150"; }

.fa.fa-caret-square-o-up {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-caret-square-o-up:before {
  content: "\f151"; }

.fa.fa-toggle-up {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-toggle-up:before {
  content: "\f151"; }

.fa.fa-caret-square-o-right {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-caret-square-o-right:before {
  content: "\f152"; }

.fa.fa-toggle-right {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-toggle-right:before {
  content: "\f152"; }

.fa.fa-eur:before {
  content: "\f153"; }

.fa.fa-euro:before {
  content: "\f153"; }

.fa.fa-gbp:before {
  content: "\f154"; }

.fa.fa-usd:before {
  content: "\f155"; }

.fa.fa-dollar:before {
  content: "\f155"; }

.fa.fa-inr:before {
  content: "\f156"; }

.fa.fa-rupee:before {
  content: "\f156"; }

.fa.fa-jpy:before {
  content: "\f157"; }

.fa.fa-cny:before {
  content: "\f157"; }

.fa.fa-rmb:before {
  content: "\f157"; }

.fa.fa-yen:before {
  content: "\f157"; }

.fa.fa-rub:before {
  content: "\f158"; }

.fa.fa-ruble:before {
  content: "\f158"; }

.fa.fa-rouble:before {
  content: "\f158"; }

.fa.fa-krw:before {
  content: "\f159"; }

.fa.fa-won:before {
  content: "\f159"; }

.fa.fa-btc {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bitcoin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bitcoin:before {
  content: "\f15a"; }

.fa.fa-file-text:before {
  content: "\f15c"; }

.fa.fa-sort-alpha-asc:before {
  content: "\f15d"; }

.fa.fa-sort-alpha-desc:before {
  content: "\f15e"; }

.fa.fa-sort-amount-asc:before {
  content: "\f160"; }

.fa.fa-sort-amount-desc:before {
  content: "\f161"; }

.fa.fa-sort-numeric-asc:before {
  content: "\f162"; }

.fa.fa-sort-numeric-desc:before {
  content: "\f163"; }

.fa.fa-youtube-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-youtube {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-xing {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-xing-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-youtube-play {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-youtube-play:before {
  content: "\f167"; }

.fa.fa-dropbox {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-stack-overflow {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-instagram {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-flickr {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-adn {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bitbucket {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bitbucket-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bitbucket-square:before {
  content: "\f171"; }

.fa.fa-tumblr {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-tumblr-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-long-arrow-down:before {
  content: "\f309"; }

.fa.fa-long-arrow-up:before {
  content: "\f30c"; }

.fa.fa-long-arrow-left:before {
  content: "\f30a"; }

.fa.fa-long-arrow-right:before {
  content: "\f30b"; }

.fa.fa-apple {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-windows {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-android {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-linux {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-dribbble {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-skype {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-foursquare {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-trello {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gratipay {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gittip {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gittip:before {
  content: "\f184"; }

.fa.fa-sun-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-sun-o:before {
  content: "\f185"; }

.fa.fa-moon-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-moon-o:before {
  content: "\f186"; }

.fa.fa-vk {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-weibo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-renren {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-pagelines {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-stack-exchange {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-arrow-circle-o-right {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-arrow-circle-o-right:before {
  content: "\f35a"; }

.fa.fa-arrow-circle-o-left {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-arrow-circle-o-left:before {
  content: "\f359"; }

.fa.fa-caret-square-o-left {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-caret-square-o-left:before {
  content: "\f191"; }

.fa.fa-toggle-left {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-toggle-left:before {
  content: "\f191"; }

.fa.fa-dot-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-dot-circle-o:before {
  content: "\f192"; }

.fa.fa-vimeo-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-try:before {
  content: "\f195"; }

.fa.fa-turkish-lira:before {
  content: "\f195"; }

.fa.fa-plus-square-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-plus-square-o:before {
  content: "\f0fe"; }

.fa.fa-slack {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wordpress {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-openid {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-institution:before {
  content: "\f19c"; }

.fa.fa-bank:before {
  content: "\f19c"; }

.fa.fa-mortar-board:before {
  content: "\f19d"; }

.fa.fa-yahoo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-reddit {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-reddit-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-stumbleupon-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-stumbleupon {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-delicious {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-digg {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-pied-piper-pp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-pied-piper-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-drupal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-joomla {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-spoon:before {
  content: "\f2e5"; }

.fa.fa-behance {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-behance-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-steam {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-steam-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-automobile:before {
  content: "\f1b9"; }

.fa.fa-cab:before {
  content: "\f1ba"; }

.fa.fa-envelope-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-envelope-o:before {
  content: "\f0e0"; }

.fa.fa-deviantart {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-soundcloud {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-file-pdf-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-pdf-o:before {
  content: "\f1c1"; }

.fa.fa-file-word-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-word-o:before {
  content: "\f1c2"; }

.fa.fa-file-excel-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-excel-o:before {
  content: "\f1c3"; }

.fa.fa-file-powerpoint-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-powerpoint-o:before {
  content: "\f1c4"; }

.fa.fa-file-image-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-image-o:before {
  content: "\f1c5"; }

.fa.fa-file-photo-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-photo-o:before {
  content: "\f1c5"; }

.fa.fa-file-picture-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-picture-o:before {
  content: "\f1c5"; }

.fa.fa-file-archive-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-archive-o:before {
  content: "\f1c6"; }

.fa.fa-file-zip-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-zip-o:before {
  content: "\f1c6"; }

.fa.fa-file-audio-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-audio-o:before {
  content: "\f1c7"; }

.fa.fa-file-sound-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-sound-o:before {
  content: "\f1c7"; }

.fa.fa-file-video-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-video-o:before {
  content: "\f1c8"; }

.fa.fa-file-movie-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-movie-o:before {
  content: "\f1c8"; }

.fa.fa-file-code-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-file-code-o:before {
  content: "\f1c9"; }

.fa.fa-vine {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-codepen {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-jsfiddle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-life-ring {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-life-bouy {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-life-bouy:before {
  content: "\f1cd"; }

.fa.fa-life-buoy {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-life-buoy:before {
  content: "\f1cd"; }

.fa.fa-life-saver {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-life-saver:before {
  content: "\f1cd"; }

.fa.fa-support {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-support:before {
  content: "\f1cd"; }

.fa.fa-circle-o-notch:before {
  content: "\f1ce"; }

.fa.fa-rebel {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ra {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ra:before {
  content: "\f1d0"; }

.fa.fa-resistance {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-resistance:before {
  content: "\f1d0"; }

.fa.fa-empire {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ge {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ge:before {
  content: "\f1d1"; }

.fa.fa-git-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-git {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-hacker-news {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-y-combinator-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-y-combinator-square:before {
  content: "\f1d4"; }

.fa.fa-yc-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-yc-square:before {
  content: "\f1d4"; }

.fa.fa-tencent-weibo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-qq {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-weixin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wechat {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wechat:before {
  content: "\f1d7"; }

.fa.fa-send:before {
  content: "\f1d8"; }

.fa.fa-paper-plane-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-paper-plane-o:before {
  content: "\f1d8"; }

.fa.fa-send-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-send-o:before {
  content: "\f1d8"; }

.fa.fa-circle-thin {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-circle-thin:before {
  content: "\f111"; }

.fa.fa-header:before {
  content: "\f1dc"; }

.fa.fa-sliders:before {
  content: "\f1de"; }

.fa.fa-futbol-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-futbol-o:before {
  content: "\f1e3"; }

.fa.fa-soccer-ball-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-soccer-ball-o:before {
  content: "\f1e3"; }

.fa.fa-slideshare {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-twitch {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-yelp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-newspaper-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-newspaper-o:before {
  content: "\f1ea"; }

.fa.fa-paypal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-wallet {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-visa {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-mastercard {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-discover {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-amex {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-paypal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-stripe {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bell-slash-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-bell-slash-o:before {
  content: "\f1f6"; }

.fa.fa-trash:before {
  content: "\f2ed"; }

.fa.fa-copyright {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-eyedropper:before {
  content: "\f1fb"; }

.fa.fa-area-chart:before {
  content: "\f1fe"; }

.fa.fa-pie-chart:before {
  content: "\f200"; }

.fa.fa-line-chart:before {
  content: "\f201"; }

.fa.fa-lastfm {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-lastfm-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ioxhost {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-angellist {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-cc:before {
  content: "\f20a"; }

.fa.fa-ils:before {
  content: "\f20b"; }

.fa.fa-shekel:before {
  content: "\f20b"; }

.fa.fa-sheqel:before {
  content: "\f20b"; }

.fa.fa-meanpath {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-meanpath:before {
  content: "\f2b4"; }

.fa.fa-buysellads {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-connectdevelop {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-dashcube {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-forumbee {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-leanpub {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-sellsy {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-shirtsinbulk {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-simplybuilt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-skyatlas {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-diamond {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-diamond:before {
  content: "\f3a5"; }

.fa.fa-intersex:before {
  content: "\f224"; }

.fa.fa-facebook-official {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-facebook-official:before {
  content: "\f09a"; }

.fa.fa-pinterest-p {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-whatsapp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-hotel:before {
  content: "\f236"; }

.fa.fa-viacoin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-medium {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-y-combinator {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-yc {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-yc:before {
  content: "\f23b"; }

.fa.fa-optin-monster {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-opencart {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-expeditedssl {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-battery-4:before {
  content: "\f240"; }

.fa.fa-battery:before {
  content: "\f240"; }

.fa.fa-battery-3:before {
  content: "\f241"; }

.fa.fa-battery-2:before {
  content: "\f242"; }

.fa.fa-battery-1:before {
  content: "\f243"; }

.fa.fa-battery-0:before {
  content: "\f244"; }

.fa.fa-object-group {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-object-ungroup {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-sticky-note-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-sticky-note-o:before {
  content: "\f249"; }

.fa.fa-cc-jcb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-cc-diners-club {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-clone {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hourglass-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hourglass-o:before {
  content: "\f254"; }

.fa.fa-hourglass-1:before {
  content: "\f251"; }

.fa.fa-hourglass-2:before {
  content: "\f252"; }

.fa.fa-hourglass-3:before {
  content: "\f253"; }

.fa.fa-hand-rock-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-rock-o:before {
  content: "\f255"; }

.fa.fa-hand-grab-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-grab-o:before {
  content: "\f255"; }

.fa.fa-hand-paper-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-paper-o:before {
  content: "\f256"; }

.fa.fa-hand-stop-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-stop-o:before {
  content: "\f256"; }

.fa.fa-hand-scissors-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-scissors-o:before {
  content: "\f257"; }

.fa.fa-hand-lizard-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-lizard-o:before {
  content: "\f258"; }

.fa.fa-hand-spock-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-spock-o:before {
  content: "\f259"; }

.fa.fa-hand-pointer-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-pointer-o:before {
  content: "\f25a"; }

.fa.fa-hand-peace-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-hand-peace-o:before {
  content: "\f25b"; }

.fa.fa-registered {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-creative-commons {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gg {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gg-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-tripadvisor {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-odnoklassniki {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-odnoklassniki-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-get-pocket {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wikipedia-w {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-safari {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-chrome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-firefox {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-opera {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-internet-explorer {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-television:before {
  content: "\f26c"; }

.fa.fa-contao {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-500px {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-amazon {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-calendar-plus-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-calendar-plus-o:before {
  content: "\f271"; }

.fa.fa-calendar-minus-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-calendar-minus-o:before {
  content: "\f272"; }

.fa.fa-calendar-times-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-calendar-times-o:before {
  content: "\f273"; }

.fa.fa-calendar-check-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-calendar-check-o:before {
  content: "\f274"; }

.fa.fa-map-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-map-o:before {
  content: "\f279"; }

.fa.fa-commenting:before {
  content: "\f4ad"; }

.fa.fa-commenting-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-commenting-o:before {
  content: "\f4ad"; }

.fa.fa-houzz {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-vimeo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-vimeo:before {
  content: "\f27d"; }

.fa.fa-black-tie {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-fonticons {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-reddit-alien {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-edge {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-credit-card-alt:before {
  content: "\f09d"; }

.fa.fa-codiepie {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-modx {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-fort-awesome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-usb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-product-hunt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-mixcloud {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-scribd {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-pause-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-pause-circle-o:before {
  content: "\f28b"; }

.fa.fa-stop-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-stop-circle-o:before {
  content: "\f28d"; }

.fa.fa-bluetooth {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-bluetooth-b {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-gitlab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wpbeginner {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wpforms {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-envira {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wheelchair-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wheelchair-alt:before {
  content: "\f368"; }

.fa.fa-question-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-question-circle-o:before {
  content: "\f059"; }

.fa.fa-volume-control-phone:before {
  content: "\f2a0"; }

.fa.fa-asl-interpreting:before {
  content: "\f2a3"; }

.fa.fa-deafness:before {
  content: "\f2a4"; }

.fa.fa-hard-of-hearing:before {
  content: "\f2a4"; }

.fa.fa-glide {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-glide-g {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-signing:before {
  content: "\f2a7"; }

.fa.fa-viadeo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-viadeo-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-snapchat {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-snapchat-ghost {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-snapchat-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-pied-piper {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-first-order {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-yoast {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-themeisle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-plus-official {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-plus-official:before {
  content: "\f2b3"; }

.fa.fa-google-plus-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-google-plus-circle:before {
  content: "\f2b3"; }

.fa.fa-font-awesome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-fa {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-fa:before {
  content: "\f2b4"; }

.fa.fa-handshake-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-handshake-o:before {
  content: "\f2b5"; }

.fa.fa-envelope-open-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-envelope-open-o:before {
  content: "\f2b6"; }

.fa.fa-linode {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-address-book-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-address-book-o:before {
  content: "\f2b9"; }

.fa.fa-vcard:before {
  content: "\f2bb"; }

.fa.fa-address-card-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-address-card-o:before {
  content: "\f2bb"; }

.fa.fa-vcard-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-vcard-o:before {
  content: "\f2bb"; }

.fa.fa-user-circle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-user-circle-o:before {
  content: "\f2bd"; }

.fa.fa-user-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-user-o:before {
  content: "\f007"; }

.fa.fa-id-badge {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-drivers-license:before {
  content: "\f2c2"; }

.fa.fa-id-card-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-id-card-o:before {
  content: "\f2c2"; }

.fa.fa-drivers-license-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-drivers-license-o:before {
  content: "\f2c2"; }

.fa.fa-quora {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-free-code-camp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-telegram {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-thermometer-4:before {
  content: "\f2c7"; }

.fa.fa-thermometer:before {
  content: "\f2c7"; }

.fa.fa-thermometer-3:before {
  content: "\f2c8"; }

.fa.fa-thermometer-2:before {
  content: "\f2c9"; }

.fa.fa-thermometer-1:before {
  content: "\f2ca"; }

.fa.fa-thermometer-0:before {
  content: "\f2cb"; }

.fa.fa-bathtub:before {
  content: "\f2cd"; }

.fa.fa-s15:before {
  content: "\f2cd"; }

.fa.fa-window-maximize {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-window-restore {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-times-rectangle:before {
  content: "\f410"; }

.fa.fa-window-close-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-window-close-o:before {
  content: "\f410"; }

.fa.fa-times-rectangle-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-times-rectangle-o:before {
  content: "\f410"; }

.fa.fa-bandcamp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-grav {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-etsy {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-imdb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-ravelry {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-eercast {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-eercast:before {
  content: "\f2da"; }

.fa.fa-snowflake-o {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

.fa.fa-snowflake-o:before {
  content: "\f2dc"; }

.fa.fa-superpowers {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-wpexplorer {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

.fa.fa-spotify {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

/* common */
/******************************************
* font-size
******************************************/
/******************************************
* ie11のみ適応
******************************************/
.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.mt85 {
  margin-top: 85px !important; }

.mr85 {
  margin-right: 85px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.ml85 {
  margin-left: 85px !important; }

.pt85 {
  padding-top: 85px !important; }

.pr85 {
  padding-right: 85px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.pl85 {
  padding-left: 85px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.ml90 {
  margin-left: 90px !important; }

.pt90 {
  padding-top: 90px !important; }

.pr90 {
  padding-right: 90px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.pl90 {
  padding-left: 90px !important; }

.mt95 {
  margin-top: 95px !important; }

.mr95 {
  margin-right: 95px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.ml95 {
  margin-left: 95px !important; }

.pt95 {
  padding-top: 95px !important; }

.pr95 {
  padding-right: 95px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.pl95 {
  padding-left: 95px !important; }

.mt100 {
  margin-top: 100px !important; }

.mr100 {
  margin-right: 100px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.ml100 {
  margin-left: 100px !important; }

.pt100 {
  padding-top: 100px !important; }

.pr100 {
  padding-right: 100px !important; }

.pb100 {
  padding-bottom: 100px !important; }

.pl100 {
  padding-left: 100px !important; }

.mt105 {
  margin-top: 105px !important; }

.mr105 {
  margin-right: 105px !important; }

.mb105 {
  margin-bottom: 105px !important; }

.ml105 {
  margin-left: 105px !important; }

.pt105 {
  padding-top: 105px !important; }

.pr105 {
  padding-right: 105px !important; }

.pb105 {
  padding-bottom: 105px !important; }

.pl105 {
  padding-left: 105px !important; }

.mt110 {
  margin-top: 110px !important; }

.mr110 {
  margin-right: 110px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.ml110 {
  margin-left: 110px !important; }

.pt110 {
  padding-top: 110px !important; }

.pr110 {
  padding-right: 110px !important; }

.pb110 {
  padding-bottom: 110px !important; }

.pl110 {
  padding-left: 110px !important; }

.mt115 {
  margin-top: 115px !important; }

.mr115 {
  margin-right: 115px !important; }

.mb115 {
  margin-bottom: 115px !important; }

.ml115 {
  margin-left: 115px !important; }

.pt115 {
  padding-top: 115px !important; }

.pr115 {
  padding-right: 115px !important; }

.pb115 {
  padding-bottom: 115px !important; }

.pl115 {
  padding-left: 115px !important; }

.mt120 {
  margin-top: 120px !important; }

.mr120 {
  margin-right: 120px !important; }

.mb120 {
  margin-bottom: 120px !important; }

.ml120 {
  margin-left: 120px !important; }

.pt120 {
  padding-top: 120px !important; }

.pr120 {
  padding-right: 120px !important; }

.pb120 {
  padding-bottom: 120px !important; }

.pl120 {
  padding-left: 120px !important; }

.mt125 {
  margin-top: 125px !important; }

.mr125 {
  margin-right: 125px !important; }

.mb125 {
  margin-bottom: 125px !important; }

.ml125 {
  margin-left: 125px !important; }

.pt125 {
  padding-top: 125px !important; }

.pr125 {
  padding-right: 125px !important; }

.pb125 {
  padding-bottom: 125px !important; }

.pl125 {
  padding-left: 125px !important; }

.mt130 {
  margin-top: 130px !important; }

.mr130 {
  margin-right: 130px !important; }

.mb130 {
  margin-bottom: 130px !important; }

.ml130 {
  margin-left: 130px !important; }

.pt130 {
  padding-top: 130px !important; }

.pr130 {
  padding-right: 130px !important; }

.pb130 {
  padding-bottom: 130px !important; }

.pl130 {
  padding-left: 130px !important; }

.mt135 {
  margin-top: 135px !important; }

.mr135 {
  margin-right: 135px !important; }

.mb135 {
  margin-bottom: 135px !important; }

.ml135 {
  margin-left: 135px !important; }

.pt135 {
  padding-top: 135px !important; }

.pr135 {
  padding-right: 135px !important; }

.pb135 {
  padding-bottom: 135px !important; }

.pl135 {
  padding-left: 135px !important; }

.mt140 {
  margin-top: 140px !important; }

.mr140 {
  margin-right: 140px !important; }

.mb140 {
  margin-bottom: 140px !important; }

.ml140 {
  margin-left: 140px !important; }

.pt140 {
  padding-top: 140px !important; }

.pr140 {
  padding-right: 140px !important; }

.pb140 {
  padding-bottom: 140px !important; }

.pl140 {
  padding-left: 140px !important; }

.mt145 {
  margin-top: 145px !important; }

.mr145 {
  margin-right: 145px !important; }

.mb145 {
  margin-bottom: 145px !important; }

.ml145 {
  margin-left: 145px !important; }

.pt145 {
  padding-top: 145px !important; }

.pr145 {
  padding-right: 145px !important; }

.pb145 {
  padding-bottom: 145px !important; }

.pl145 {
  padding-left: 145px !important; }

.mt150 {
  margin-top: 150px !important; }

.mr150 {
  margin-right: 150px !important; }

.mb150 {
  margin-bottom: 150px !important; }

.ml150 {
  margin-left: 150px !important; }

.pt150 {
  padding-top: 150px !important; }

.pr150 {
  padding-right: 150px !important; }

.pb150 {
  padding-bottom: 150px !important; }

.pl150 {
  padding-left: 150px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mt-5 {
  margin-top: -5px !important; }

.mr-5 {
  margin-right: -5px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.ml-5 {
  margin-left: -5px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mr-10 {
  margin-right: -10px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.ml-10 {
  margin-left: -10px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mr-15 {
  margin-right: -15px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.ml-15 {
  margin-left: -15px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mr-20 {
  margin-right: -20px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.ml-20 {
  margin-left: -20px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mr-25 {
  margin-right: -25px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.ml-25 {
  margin-left: -25px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mr-30 {
  margin-right: -30px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.ml-30 {
  margin-left: -30px !important; }

.mt-35 {
  margin-top: -35px !important; }

.mr-35 {
  margin-right: -35px !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.ml-35 {
  margin-left: -35px !important; }

.mt-40 {
  margin-top: -40px !important; }

.mr-40 {
  margin-right: -40px !important; }

.mb-40 {
  margin-bottom: -40px !important; }

.ml-40 {
  margin-left: -40px !important; }

.mt-45 {
  margin-top: -45px !important; }

.mr-45 {
  margin-right: -45px !important; }

.mb-45 {
  margin-bottom: -45px !important; }

.ml-45 {
  margin-left: -45px !important; }

.mt-50 {
  margin-top: -50px !important; }

.mr-50 {
  margin-right: -50px !important; }

.mb-50 {
  margin-bottom: -50px !important; }

.ml-50 {
  margin-left: -50px !important; }

.mt-55 {
  margin-top: -55px !important; }

.mr-55 {
  margin-right: -55px !important; }

.mb-55 {
  margin-bottom: -55px !important; }

.ml-55 {
  margin-left: -55px !important; }

.mt-60 {
  margin-top: -60px !important; }

.mr-60 {
  margin-right: -60px !important; }

.mb-60 {
  margin-bottom: -60px !important; }

.ml-60 {
  margin-left: -60px !important; }

.mt-65 {
  margin-top: -65px !important; }

.mr-65 {
  margin-right: -65px !important; }

.mb-65 {
  margin-bottom: -65px !important; }

.ml-65 {
  margin-left: -65px !important; }

.mt-70 {
  margin-top: -70px !important; }

.mr-70 {
  margin-right: -70px !important; }

.mb-70 {
  margin-bottom: -70px !important; }

.ml-70 {
  margin-left: -70px !important; }

.mt-75 {
  margin-top: -75px !important; }

.mr-75 {
  margin-right: -75px !important; }

.mb-75 {
  margin-bottom: -75px !important; }

.ml-75 {
  margin-left: -75px !important; }

.mt-80 {
  margin-top: -80px !important; }

.mr-80 {
  margin-right: -80px !important; }

.mb-80 {
  margin-bottom: -80px !important; }

.ml-80 {
  margin-left: -80px !important; }

.mt-85 {
  margin-top: -85px !important; }

.mr-85 {
  margin-right: -85px !important; }

.mb-85 {
  margin-bottom: -85px !important; }

.ml-85 {
  margin-left: -85px !important; }

.mt-90 {
  margin-top: -90px !important; }

.mr-90 {
  margin-right: -90px !important; }

.mb-90 {
  margin-bottom: -90px !important; }

.ml-90 {
  margin-left: -90px !important; }

.mt-95 {
  margin-top: -95px !important; }

.mr-95 {
  margin-right: -95px !important; }

.mb-95 {
  margin-bottom: -95px !important; }

.ml-95 {
  margin-left: -95px !important; }

.mt-100 {
  margin-top: -100px !important; }

.mr-100 {
  margin-right: -100px !important; }

.mb-100 {
  margin-bottom: -100px !important; }

.ml-100 {
  margin-left: -100px !important; }

* {
  box-sizing: border-box;
  margin: 0; }

a {
  color: #0C53A4;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: underline; }

@media screen and (min-width: 0) and (max-width: 878px) {
  br.wbr {
    display: none; } }

img {
  max-width: 100%;
  height: auto; }
  img.mix-darken {
    mix-blend-mode: darken; }

html {
  scroll-behavior: smooth; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    html.scroll-prevent {
      cursor: pointer;
      /*動き固定*/
      position: fixed;
      /*奥行きを管理*/
      z-index: -1;
      /*下2つで背景を元のサイズのまま表示することができる*/
      width: 100%;
      height: 100%; } }

body {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.container {
  padding: 0 10px; }

.wrapper {
  width: 100%;
  max-width: 1165px;
  margin: 0 auto; }

.wrapper-sm {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto; }

.align-center {
  text-align: center; }

.v-middle {
  vertical-align: middle; }

.ws-pl {
  white-space: pre-line; }

.negative-indent {
  text-indent: -1em;
  padding-left: 1em; }

.common-header {
  margin-bottom: 1rem; }
  .common-header .ja,
  .common-header .en {
    display: block;
    line-height: 1.25; }
  .common-header .ja {
    font-size: 1.75rem;
    font-weight: normal; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .common-header .ja {
        font-size: 1.25rem; } }
  .common-header .en {
    font-size: 1.5rem;
    color: #ddd;
    letter-spacing: 1px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .common-header .en {
        font-size: 1.25rem; } }
  .common-header::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #E52019;
    margin-top: .5rem; }

.common-h2 {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.125;
  margin-bottom: 2.5rem; }

.common-h3 {
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 1.125rem; }

.text-light {
  color: #373A39; }

.notices {
  margin-bottom: 4rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .notices {
      margin-bottom: 3rem; } }
  .notices .notice {
    padding: 2.5rem 3rem;
    border: solid 1px #D8D8D8;
    margin-bottom: 1rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .notices .notice {
        padding: 1.25rem 1.5rem; } }
    .notices .notice .info {
      font-size: .75rem;
      margin-bottom: 3rem; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .notices .notice .info {
          margin-bottom: 2rem; } }
      .notices .notice .info.info-top {
        margin-bottom: 1.5rem; }
      .notices .notice .info .tag {
        display: inline-block;
        background-color: #f7f7f7;
        font-weight: bold;
        padding: .1875rem .25rem;
        border: solid 1px #e2e2e2;
        border-radius: 4px; }
        .notices .notice .info .tag:empty {
          display: none; }
    .notices .notice .notice-title {
      color: inherit;
      line-height: 1.5; }

.navigation.pagination[role="navigation"] .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.navigation.pagination[role="navigation"] .nav-links .page-numbers {
  display: inline-block;
  background-color: #ccc;
  color: white;
  padding: .75rem 1rem;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.125);
  border-radius: 1px;
  margin-bottom: .25rem; }
  .navigation.pagination[role="navigation"] .nav-links .page-numbers.current {
    background-color: #999; }
  .navigation.pagination[role="navigation"] .nav-links .page-numbers:hover, .navigation.pagination[role="navigation"] .nav-links .page-numbers:focus {
    background-color: #B3B3B3; }

/* wordpress editor -------- */
.editor {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  overflow: hidden; }
  .editor > :first-child {
    margin-top: 0; }
  .editor .aligncenter {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px; }
  .editor .alignright {
    float: right;
    padding-left: 20px;
    padding-bottom: 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .editor .alignright {
        float: none;
        padding-left: 0;
        padding-bottom: 0; } }
  .editor .alignleft {
    float: left;
    padding-right: 20px;
    padding-bottom: 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .editor .alignleft {
        float: none;
        padding-right: 0;
        padding-bottom: 0; } }
  .editor img[class*="wp-image-"], .editor img[class*="attachment-"] {
    max-width: 100%;
    height: auto; }
  .editor a {
    color: #0C53A4;
    text-decoration: none; }
    .editor a:hover {
      color: #69f; }
  .editor h1 {
    margin: 50px 0 40px;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.25; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .editor h1 {
        font-size: 1.25rem; } }
  .editor h2 {
    margin: 40px 0 30px;
    font-size: 150%;
    font-weight: bold;
    line-height: 1.25; }
  .editor h3 {
    margin: 30px 0 20px;
    font-size: 125%;
    font-weight: bold;
    line-height: 1.25; }
  .editor p, .editor ul, .editor ol, .editor table {
    font-size: .875rem;
    line-height: 1.875; }
  .editor li {
    margin-bottom: 10px; }
  .editor ul {
    list-style-type: disc;
    padding-left: 1.5em; }
  .editor ol {
    list-style-type: decimal;
    padding-left: 1.5em; }
  .editor hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #999; }
  .editor table {
    width: auto !important;
    height: auto !important;
    border-collapse: collapse; }
  .editor th, .editor td {
    padding: 10px 20px;
    border: 1px solid #b5b5b5; }
  .editor th {
    background-color: #eee; }
  .editor strong {
    font-weight: bold; }
  .editor em {
    font-style: italic; }
  .editor del {
    text-decoration: line-through; }

.pagetop {
  text-align: center;
  margin-bottom: 2rem; }
  .pagetop a {
    display: block;
    transition: opacity .2s; }
    .pagetop a:hover {
      filter: alpha(opacity=70);
      -moz-opacity: 0.7;
      opacity: 0.7; }

.col {
  overflow: hidden;
  padding-top: 10px;
  margin-top: -10px; }
  .col .col-4 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .col .col-4 > * {
      width: calc(100% / 4 - 10px * 2 - 1px);
      max-width: calc(100% / 4 - 10px * 2);
      flex: 1 0 auto;
      margin: 0 10px 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .col .col-4 > * {
        width: calc(100% / 2 - 10px * 2 - 1px);
        max-width: calc(100% / 2 - 10px * 2);
        margin: 0 10px 20px; } }
  .col .col-3 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
    .col .col-3 > * {
      width: calc(100% / 3 - 15px * 2 - 1px);
      max-width: calc(100% / 3 - 15px * 2);
      flex: 1 0 auto;
      margin: 0 15px 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .col .col-3 {
        margin: 0; }
        .col .col-3 > * {
          width: 100%;
          max-width: none;
          margin: 0 0 20px; } }
  .col .col-2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
    .col .col-2 > * {
      width: calc(100% / 2 - 15px * 2 - 1px);
      max-width: calc(100% / 2 - 15px * 2);
      flex: 1 0 auto;
      margin: 0 15px 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .col .col-2 {
        margin: 0; }
        .col .col-2 > * {
          width: 100%;
          max-width: none;
          margin: 0 0 20px; } }
  .col .col-2m {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px; }
    .col .col-2m > * {
      width: calc(100% / 2 - 20px * 2 - 1px);
      max-width: calc(100% / 2 - 20px * 2);
      flex: 1 0 auto;
      margin: 0 20px 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .col .col-2m {
        margin: 0; }
        .col .col-2m > * {
          width: 100%;
          max-width: none;
          margin: 0 0 20px; } }

.btn_wrap .more_btn {
  display: inline-block;
  border: solid 1px #D8D8D8;
  font-size: 0.85rem;
  color: #000;
  padding: 10px 20px;
  text-decoration: none;
  transition: 0.2s;
  margin-top: 1rem; }
  .btn_wrap .more_btn:hover {
    background-color: #888;
    color: white; }

.btn_wrap .back_btn {
  display: inline-block;
  border: solid 1px #D8D8D8;
  font-size: 0.85rem;
  color: #000;
  padding: 10px 20px;
  text-decoration: none;
  transition: 0.2s;
  margin-top: 1rem; }
  .btn_wrap .back_btn:hover {
    background-color: #888;
    color: white; }

/* parts */
#header {
  line-height: 1.5;
  padding: 1.25rem 0; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    #header {
      padding: 1rem 0; } }
  #header .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #header .flex-container .flex-left {
      padding-top: 1.25rem;
      display: flex; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #header .flex-container .flex-left {
          padding-top: 0;
          align-items: center; } }
      #header .flex-container .flex-left .logo {
        flex: none;
        max-width: 60px;
        margin-right: 1rem; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #header .flex-container .flex-left .logo {
            max-width: 27px;
            margin-right: .75rem; } }
        #header .flex-container .flex-left .logo a img {
          transition: opacity .2s;
          display: block; }
          #header .flex-container .flex-left .logo a img:hover {
            filter: alpha(opacity=70);
            -moz-opacity: 0.7;
            opacity: 0.7; }
      #header .flex-container .flex-left .title {
        flex: 1 1 auto; }
        #header .flex-container .flex-left .title h1 {
          margin-top: .75rem; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #header .flex-container .flex-left .title h1 {
              max-width: 240px;
              margin-top: 0;
              line-height: 1; } }
        #header .flex-container .flex-left .title address {
          font-size: .875rem;
          font-style: normal; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #header .flex-container .flex-left .title address {
              font-size: .65rem;
              line-height: 1.25;
              white-space: nowrap; } }
    #header .flex-container .flex-right {
      position: relative; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #header .flex-container .flex-right {
          padding-left: .5rem; } }
      #header .flex-container .flex-right .a-lang,
      #header .flex-container .flex-right #openMenu {
        vertical-align: middle; }
      #header .flex-container .flex-right .a-lang {
        display: inline-block;
        min-width: 80px;
        background-color: black;
        color: white;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        margin-right: 2rem;
        transition: opacity .2s; }
        #header .flex-container .flex-right .a-lang:hover {
          filter: alpha(opacity=70);
          -moz-opacity: 0.7;
          opacity: 0.7; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #header .flex-container .flex-right .a-lang {
            display: none; } }
      #header .flex-container .flex-right #openMenu {
        display: inline-block;
        background: none;
        padding: 0;
        border: 0;
        cursor: pointer; }
        #header .flex-container .flex-right #openMenu .hamburger {
          position: relative;
          width: 2.25rem;
          height: 3px;
          background-color: black;
          margin-top: 10px;
          margin-bottom: 10px; }
          #header .flex-container .flex-right #openMenu .hamburger::before, #header .flex-container .flex-right #openMenu .hamburger::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: black; }
          #header .flex-container .flex-right #openMenu .hamburger::before {
            top: 10px; }
          #header .flex-container .flex-right #openMenu .hamburger::after {
            bottom: 10px; }
      #header .flex-container .flex-right #menu {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2147483647;
        min-width: 20em;
        background-color: black;
        color: white;
        font-size: .875rem;
        padding-bottom: 1rem;
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s, visibility .25s step-end; }
        #header .flex-container .flex-right #menu a {
          pointer-events: none; }
        #header .flex-container .flex-right #menu.is-shown {
          opacity: 1;
          visibility: visible;
          transition: opacity .25s, visibility .25s step-start; }
          #header .flex-container .flex-right #menu.is-shown a {
            pointer-events: auto; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #header .flex-container .flex-right #menu {
            position: fixed;
            overflow: auto;
            left: 25%;
            bottom: 0;
            min-width: 0;
            opacity: 1;
            transform: translateX(100%);
            transition: transform .25s, visibility .25s step-end; }
            #header .flex-container .flex-right #menu.is-shown {
              transform: none;
              transition: transform .25s, visibility .25s step-start; } }
        #header .flex-container .flex-right #menu header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.125em 1.5em;
          border-bottom: dashed 1px #8D8888; }
          #header .flex-container .flex-right #menu header .menu-title {
            line-height: 1; }
          #header .flex-container .flex-right #menu header #closeMenu {
            background: none;
            color: white;
            padding: 0;
            border: none;
            cursor: pointer; }
            #header .flex-container .flex-right #menu header #closeMenu .cross {
              position: relative;
              height: 1.5rem; }
              #header .flex-container .flex-right #menu header #closeMenu .cross::before, #header .flex-container .flex-right #menu header #closeMenu .cross::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 1rem;
                height: 3px;
                background-color: white; }
              #header .flex-container .flex-right #menu header #closeMenu .cross::before {
                transform: rotate(45deg); }
              #header .flex-container .flex-right #menu header #closeMenu .cross::after {
                transform: rotate(-45deg); }
            #header .flex-container .flex-right #menu header #closeMenu .label {
              line-height: 1;
              font-size: .625rem; }
        #header .flex-container .flex-right #menu ul {
          list-style: none;
          padding-left: 0; }
          #header .flex-container .flex-right #menu ul li {
            border-bottom: dashed 1px #8D8888; }
            #header .flex-container .flex-right #menu ul li a {
              display: block;
              color: inherit;
              text-decoration: none;
              padding: .5625em 1.5em; }
              #header .flex-container .flex-right #menu ul li a:hover, #header .flex-container .flex-right #menu ul li a:focus {
                text-decoration: underline; }
            #header .flex-container .flex-right #menu ul li ul li {
              border-bottom-style: none; }
              #header .flex-container .flex-right #menu ul li ul li a {
                padding-left: 3.5em; }
          #header .flex-container .flex-right #menu ul .only-sp {
            display: none; }
            @media screen and (min-width: 0) and (max-width: 878px) {
              #header .flex-container .flex-right #menu ul .only-sp {
                display: block; } }
        #header .flex-container .flex-right #menu .login-link {
          display: none; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #header .flex-container .flex-right #menu .login-link {
              display: block;
              text-align: center;
              margin-top: 20px; }
              #header .flex-container .flex-right #menu .login-link .btn {
                display: inline-block;
                width: auto;
                background-color: #E52019;
                color: white;
                font-size: .875rem;
                text-align: center;
                text-decoration: none;
                line-height: 1.25;
                padding: 0.75rem;
                border: none;
                border-radius: 3px;
                transition: background-color .25s; }
                #header .flex-container .flex-right #menu .login-link .btn:hover, #header .flex-container .flex-right #menu .login-link .btn:focus {
                  background-color: #eb4c46; } }

#footer {
  background-color: #2D2D2D;
  color: #7c7c7c; }
  #footer .footer-wrapper {
    max-width: 970px;
    margin-left: auto; }
    #footer .footer-wrapper .links {
      padding: 1.5rem 1.5rem 5rem;
      text-align: right; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #footer .footer-wrapper .links {
          padding: 2rem 1rem 2rem; } }
      #footer .footer-wrapper .links ul {
        display: inline-block;
        padding-left: 0;
        vertical-align: top; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #footer .footer-wrapper .links ul {
            display: block; } }
        #footer .footer-wrapper .links ul li {
          display: inline-block;
          font-size: .875rem;
          margin-left: 2rem;
          margin-top: 10px;
          vertical-align: top; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #footer .footer-wrapper .links ul li {
              text-align: left;
              display: block;
              margin-left: 0;
              margin-top: 0; } }
          #footer .footer-wrapper .links ul li a {
            color: inherit;
            text-decoration: none;
            transition: color .25s; }
            #footer .footer-wrapper .links ul li a:hover, #footer .footer-wrapper .links ul li a:focus {
              color: white; }
            @media screen and (min-width: 0) and (max-width: 878px) {
              #footer .footer-wrapper .links ul li a {
                display: block;
                padding: 10px 0; } }
          #footer .footer-wrapper .links ul li ul li {
            margin-top: 0; }
    #footer .footer-wrapper .o_links {
      padding: 1.5rem;
      padding-bottom: 0;
      text-align: right; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #footer .footer-wrapper .o_links {
          text-align: left;
          padding: 2rem 1rem 0; } }
      #footer .footer-wrapper .o_links ul {
        display: block;
        padding-left: 0; }
        #footer .footer-wrapper .o_links ul li {
          display: inline-block; }
          #footer .footer-wrapper .o_links ul li a {
            display: block;
            text-decoration: none;
            transition: all .25s;
            font-size: .875rem;
            border: 1px solid #999;
            color: #999;
            border-radius: 4px;
            padding: 10px; }
            #footer .footer-wrapper .o_links ul li a:hover, #footer .footer-wrapper .o_links ul li a:focus {
              background-color: rgba(255, 255, 255, 0.5);
              border: 1px solid rgba(255, 255, 255, 0.5);
              color: #fff; }
            #footer .footer-wrapper .o_links ul li a i {
              margin-left: 5px; }
    #footer .footer-wrapper .copyright {
      font-size: .75rem;
      padding: .5rem 1.5rem 3rem;
      border-top: solid 1px #7c7c7c;
      text-align: right; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #footer .footer-wrapper .copyright {
          font-size: .875rem;
          text-align: left;
          padding: .5rem 1rem 1.5rem; } }
      #footer .footer-wrapper .copyright .warning {
        margin-bottom: 3rem; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #footer .footer-wrapper .copyright .warning {
            margin-bottom: 1.5rem; } }
      #footer .footer-wrapper .copyright .copy {
        font-style: italic; }

/* page */
#page_service .visual img {
  width: 100%; }

#page_service .sales {
  background-position: top left;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  min-height: 756px; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    #page_service .sales {
      min-height: 0; } }
  #page_service .sales .sales_list_content {
    max-width: 620px;
    position: absolute;
    top: -72px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      #page_service .sales .sales_list_content {
        margin-top: 10px;
        position: static; } }
    #page_service .sales .sales_list_content ol {
      list-style: none;
      font-size: 20px;
      color: #fff;
      margin: 0 25px; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #page_service .sales .sales_list_content ol {
          margin: 0 10px;
          font-size: 1.8rem; } }
      #page_service .sales .sales_list_content ol li {
        background-color: #E74897;
        border: 6px solid #fff;
        border-radius: 13px;
        padding: 12px 25px;
        margin-bottom: 5px;
        font-weight: bold;
        line-height: 1.5; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .sales .sales_list_content ol li {
            padding-left: 10px;
            padding-right: 10px; } }
        #page_service .sales .sales_list_content ol li:last-child {
          background-color: #F5A623; }
        #page_service .sales .sales_list_content ol li .list_box01 {
          margin-top: 10px;
          padding: 0 28px; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .sales .sales_list_content ol li .list_box01 {
              padding: 0; } }
          #page_service .sales .sales_list_content ol li .list_box01 .fl_b {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            @media screen and (min-width: 0) and (max-width: 878px) {
              #page_service .sales .sales_list_content ol li .list_box01 .fl_b {
                display: block; } }
            #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col1 {
              text-align: center; }
              #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col1 p {
                background-color: #fff;
                color: #000;
                font-weight: bold;
                font-size: 18px;
                padding: 10px 20px;
                margin-bottom: 5px; }
                @media screen and (min-width: 0) and (max-width: 878px) {
                  #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col1 p {
                    font-size: 1.6rem; } }
            #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col2 p {
              font-size: 22px; }
              @media screen and (min-width: 0) and (max-width: 878px) {
                #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col2 p {
                  text-align: center; }
                  #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col2 p br {
                    display: none; } }
              #page_service .sales .sales_list_content ol li .list_box01 .fl_b .col2 p span.big {
                font-size: 40px; }
          #page_service .sales .sales_list_content ol li .list_box01 .notice {
            padding: 20px 0;
            font-size: 16px;
            color: #000; }
            @media screen and (min-width: 0) and (max-width: 878px) {
              #page_service .sales .sales_list_content ol li .list_box01 .notice {
                font-size: 1.2rem; } }

#page_service .section01 {
  background: #BC2E29;
  color: #fff;
  position: relative;
  text-align: center;
  font-size: 44px;
  font-weight: bold;
  height: 190px;
  padding-top: 50px;
  line-height: 1;
  box-sizing: border-box; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    #page_service .section01 {
      font-size: 2.5rem;
      height: auto;
      padding: 20px;
      padding-bottom: 40px; } }
  #page_service .section01 .content_svg {
    fill: #ffffff; }

#page_service .section02 {
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  background: #F5A623;
  border-radius: 50px;
  padding: 20px 0;
  margin-top: 35px; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    #page_service .section02 {
      font-size: 2.5rem;
      padding: 20px; } }

#page_service .section03 {
  margin-top: 33px; }
  #page_service .section03 .fw-b {
    font-weight: bold; }
  #page_service .section03 table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      #page_service .section03 table {
        display: block; }
        #page_service .section03 table tbody {
          display: block; } }
    @media screen and (min-width: 0) and (max-width: 878px) {
      #page_service .section03 table tr {
        display: block;
        border: 4px solid #DCDCDC;
        margin-top: 20px; } }
    #page_service .section03 table th {
      border-radius: 8px;
      background: #F4F4F4;
      border: 4px solid #DCDCDC;
      width: 343px;
      font-size: 24px;
      color: #000000;
      padding: 15px 30px;
      padding-right: 10px; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #page_service .section03 table th {
          display: block;
          width: auto;
          border: none;
          padding: 20px 10px;
          font-size: 1.8rem;
          border-radius: 0; } }
      #page_service .section03 table th:after {
        content: "任意";
        float: right;
        display: block;
        background-color: #4A4A4A;
        color: #fff;
        font-size: 16px;
        text-align: center;
        border-radius: 6px;
        padding: 3px 5px; }
      #page_service .section03 table th.hissu:after {
        content: "必須";
        background-color: #D43F3A; }
      #page_service .section03 table th .form_notice {
        display: none; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table th .form_notice {
            display: inline-block;
            font-size: 1.2rem;
            color: #225085;
            margin-left: 10px; } }
    #page_service .section03 table td {
      border-radius: 8px;
      border: 4px solid #DCDCDC;
      padding: 0;
      /* 丁目・番地  お名前 フリガナ*/
      /* 建物面積 */
      /* 築年数 */
      /* 間取り */
      /* ラジオボタンのみ */ }
      @media screen and (min-width: 0) and (max-width: 878px) {
        #page_service .section03 table td {
          display: block;
          border: none;
          padding: 20px 10px; } }
      #page_service .section03 table td .select__wrapper {
        text-align: center;
        position: relative;
        border: 1px solid #bbbbbb;
        border-radius: 2px;
        background: #ffffff; }
        #page_service .section03 table td .select__wrapper.no_border {
          width: 100%;
          margin: 0;
          border: none; }
          #page_service .section03 table td .select__wrapper.no_border select {
            text-indent: 30px; }
        #page_service .section03 table td .select__wrapper:before {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 20px;
          width: 0;
          height: 0;
          padding: 0;
          content: '';
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #666666;
          pointer-events: none; }
        #page_service .section03 table td .select__wrapper select {
          width: 100%;
          cursor: pointer;
          text-indent: 10px;
          text-overflow: ellipsis;
          border: none;
          outline: none;
          background: transparent;
          background-image: none;
          box-shadow: none;
          -webkit-appearance: none;
          appearance: none;
          color: #000;
          font-size: 16px;
          margin: 0; }
          #page_service .section03 table td .select__wrapper select:hover {
            opacity: 0.7; }
          #page_service .section03 table td .select__wrapper select::-ms-expand {
            display: none; }
      #page_service .section03 table td .input__wrapper {
        border: 1px solid #bbbbbb;
        border-radius: 2px;
        background: #ffffff; }
        #page_service .section03 table td .input__wrapper input {
          width: 100%;
          border: none;
          outline: none;
          color: #000;
          font-size: 16px;
          margin: 0; }
        #page_service .section03 table td .input__wrapper.no_border {
          width: 100%;
          margin: 0;
          border: none; }
          #page_service .section03 table td .input__wrapper.no_border input {
            text-indent: 30px; }
      #page_service .section03 table td .radio__wrapper input[type="radio"]:checked {
        background-color: #fff; }
      #page_service .section03 table td .radio__wrapper input[type="radio"]:checked:before {
        background-color: #000; }
      #page_service .section03 table td .textarea__wrapper {
        padding: 25px 40px; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td .textarea__wrapper {
            padding: 0; } }
        #page_service .section03 table td .textarea__wrapper textarea {
          box-sizing: border-box;
          width: 100%;
          margin: 0; }
      #page_service .section03 table td .form_notice {
        font-size: 14px;
        color: #225085; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td .form_notice {
            display: none; } }
      #page_service .section03 table td .fl_box {
        display: flex;
        justify-content: start;
        align-items: center; }
      #page_service .section03 table td .form_box {
        position: relative; }
        #page_service .section03 table td .form_box .error_box {
          position: absolute;
          display: inline-block;
          top: -30px;
          left: 40px;
          background-color: #fff;
          border: 1px solid red;
          padding: 5px 10px;
          display: none; }
          #page_service .section03 table td .form_box .error_box.show {
            display: block; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td .form_box .error_box {
              left: 0;
              box-shadow: 0px 2px 9px -1px #bfbfbf; } }
          #page_service .section03 table td .form_box .error_box .error {
            position: relative;
            color: red;
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap; }
            #page_service .section03 table td .form_box .error_box .error:before {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 11px 7px 0px 7px;
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              bottom: -16px;
              border-color: red transparent; }
              @media screen and (min-width: 0) and (max-width: 878px) {
                #page_service .section03 table td .form_box .error_box .error:before {
                  display: none; } }
            #page_service .section03 table td .form_box .error_box .error:after {
              border-width: 10px 6px 0 6px;
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-color: #fff transparent transparent transparent;
              position: absolute;
              margin: auto;
              bottom: -15px;
              left: 0;
              right: 0; }
              @media screen and (min-width: 0) and (max-width: 878px) {
                #page_service .section03 table td .form_box .error_box .error:after {
                  display: none; } }
      #page_service .section03 table td.table_style1 {
        padding: 0 20px; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td.table_style1 {
            padding: 20px 10px; } }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td.table_style1 .fl_box {
            display: block; } }
        #page_service .section03 table td.table_style1 label {
          display: block;
          padding: 0 20px; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.table_style1 label {
              padding: 10px;
              margin-top: 10px; } }
        #page_service .section03 table td.table_style1 .select__wrapper, #page_service .section03 table td.table_style1 .input__wrapper {
          width: 35%; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.table_style1 .select__wrapper, #page_service .section03 table td.table_style1 .input__wrapper {
              width: auto; } }
      #page_service .section03 table td.menseki {
        padding: 0 20px; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td.menseki {
            padding: 20px 10px; } }
        #page_service .section03 table td.menseki .input__wrapper {
          margin: 0 20px;
          width: 35%; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.menseki .input__wrapper {
              margin: 0;
              margin: 5px;
              width: auto; } }
        #page_service .section03 table td.menseki .radio__wrapper {
          margin-right: 20px; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.menseki .radio__wrapper {
              margin-right: 5px; } }
      #page_service .section03 table td.tikunensu {
        padding: 0 20px; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td.tikunensu {
            padding: 20px 10px; } }
        #page_service .section03 table td.tikunensu .select__wrapper, #page_service .section03 table td.tikunensu .input__wrapper {
          margin: 0 20px;
          width: 35%; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.tikunensu .select__wrapper, #page_service .section03 table td.tikunensu .input__wrapper {
              width: 100%; } }
      #page_service .section03 table td.madori {
        padding: 0 20px; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td.madori {
            padding: 20px 10px; } }
        #page_service .section03 table td.madori .select__wrapper, #page_service .section03 table td.madori .input__wrapper {
          margin: 0 20px;
          width: 35%; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.madori .select__wrapper, #page_service .section03 table td.madori .input__wrapper {
              width: 100%; } }
      #page_service .section03 table td.only_raio_box {
        padding: 0 20px; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          #page_service .section03 table td.only_raio_box {
            padding: 20px 10px; } }
        #page_service .section03 table td.only_raio_box .radio__wrapper {
          padding: 0 20px; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            #page_service .section03 table td.only_raio_box .radio__wrapper {
              margin-bottom: 10px;
              display: block; } }
  #page_service .section03 .allow {
    height: 200px;
    position: relative; }
    #page_service .section03 .allow:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 0;
      height: 0;
      margin: auto;
      border-style: solid;
      border-width: 60px 133px 0 133px;
      border-color: #bc2e29 transparent transparent transparent; }
  #page_service .section03 .submit_btn_box button {
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #D0021B;
    border-radius: 50px;
    font-size: 44px;
    font-weight: bold;
    padding: 17px 0; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      #page_service .section03 .submit_btn_box button {
        font-size: 3rem;
        padding: 17px; } }
    #page_service .section03 .submit_btn_box button:hover {
      opacity: 0.7; }

.page-index .sec {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 100%; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-index .sec {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 4rem;
      margin-bottom: 4rem;
      width: 100%; } }

.page-index .sec-login {
  min-height: 510px;
  background-image: url(../images/index/visual.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .page-index .sec-login {
      background-image: url(../images/index/visual@2x.jpg); } }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-index .sec-login {
      min-height: 35.318559556vw;
      margin-top: 0;
      margin-bottom: 0; } }
  .page-index .sec-login .login-link {
    padding-top: 3.375rem;
    margin-left: auto;
    max-width: 260px;
    color: white; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-index .sec-login .login-link {
        display: none;
        position: absolute;
        top: calc(50% - 50px);
        left: 0;
        right: 0;
        max-width: none;
        text-align: center;
        padding: 0 1rem; } }
    .page-index .sec-login .login-link h3 {
      font-size: 0.875rem;
      font-weight: normal;
      text-align: center;
      margin-bottom: .875rem; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-login .login-link h3 {
          margin-bottom: .5rem; } }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-login .login-link h3 img {
          filter: drop-shadow(0 1px 2px rgba(0, 71, 176, 0.5)); } }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-login .login-link h3 span {
          text-shadow: 0 1px 2px rgba(0, 71, 176, 0.625); } }
    .page-index .sec-login .login-link a.btn {
      display: block;
      width: 100%;
      background-color: #E52019;
      color: white;
      font-size: .875rem;
      text-align: center;
      text-decoration: none;
      line-height: 1.25;
      padding: 1.25rem;
      border: none;
      border-radius: 3px;
      transition: background-color .25s; }
      .page-index .sec-login .login-link a.btn:hover, .page-index .sec-login .login-link a.btn:focus {
        background-color: #eb4c46; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-login .login-link a.btn {
          display: inline-block;
          width: auto;
          padding: 1rem; } }

.page-index .sec-about dl {
  display: flex; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-index .sec-about dl {
      display: block; } }
  .page-index .sec-about dl dt {
    flex: none;
    margin-right: 5rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-index .sec-about dl dt {
        margin-right: 0; } }
  .page-index .sec-about dl dd {
    font-size: .875rem;
    padding-top: .5rem; }
    .page-index .sec-about dl dd p {
      line-height: 1.75;
      margin-bottom: 1rem; }

.page-index .sec-symbol {
  background-color: #F6F8FA;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  .page-index .sec-symbol h2 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 2rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-index .sec-symbol h2 {
        font-size: 1.25rem; } }
  .page-index .sec-symbol .flex-container {
    display: flex; }
    .page-index .sec-symbol .flex-container .only_sp {
      display: none; }
      @media screen and (min-width: 0) and (max-width: 425px) {
        .page-index .sec-symbol .flex-container .only_sp {
          display: block;
          text-align: center;
          padding-bottom: 20px; }
          .page-index .sec-symbol .flex-container .only_sp img {
            max-width: 100px; } }
    .page-index .sec-symbol .flex-container .flex-left {
      width: 55%; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-symbol .flex-container .flex-left {
          width: 70%; } }
      @media screen and (min-width: 0) and (max-width: 425px) {
        .page-index .sec-symbol .flex-container .flex-left {
          width: auto; } }
    .page-index .sec-symbol .flex-container .flex-right {
      width: 45%; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-symbol .flex-container .flex-right {
          padding-left: 2rem;
          padding-top: 52px;
          width: 30%; }
          .page-index .sec-symbol .flex-container .flex-right figcaption {
            display: none; } }
      @media screen and (min-width: 0) and (max-width: 425px) {
        .page-index .sec-symbol .flex-container .flex-right {
          display: none; } }
    .page-index .sec-symbol .flex-container .flex-left section {
      font-size: .875rem;
      line-height: 1.75; }
      .page-index .sec-symbol .flex-container .flex-left section h3 {
        color: #919D8E;
        font-size: 2.625rem;
        font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: normal;
        line-height: 1;
        margin-bottom: 1rem; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-index .sec-symbol .flex-container .flex-left section h3 {
            font-size: 1.85rem;
            line-height: 1.25;
            white-space: nowrap; } }
      .page-index .sec-symbol .flex-container .flex-left section ul {
        padding-left: 20px;
        margin-top: 10px; }
        .page-index .sec-symbol .flex-container .flex-left section ul li {
          margin-bottom: .75rem; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            .page-index .sec-symbol .flex-container .flex-left section ul li {
              margin-bottom: 1rem; } }
      .page-index .sec-symbol .flex-container .flex-left section p:last-of-type {
        margin-top: 1rem; }
    .page-index .sec-symbol .flex-container .flex-right figure {
      text-align: center; }
    .page-index .sec-symbol .flex-container .flex-right figcaption {
      font-size: 1.125rem;
      margin-top: 1.75rem; }

.page-index .sec-notice .sec-title {
  font-size: 2.125rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 4rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-index .sec-notice .sec-title {
      font-size: 1.5rem;
      margin-bottom: 2rem; } }

.page-index .sec-notice .notices {
  margin-bottom: 2rem; }
  .page-index .sec-notice .notices .notice {
    padding: 0;
    border: none;
    margin-bottom: 1.25rem; }
    .page-index .sec-notice .notices .notice .info-top {
      margin-bottom: .75rem; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-index .sec-notice .notices .notice .info-top {
          margin-bottom: .5rem; } }
      .page-index .sec-notice .notices .notice .info-top .notice-title {
        font-size: 1rem;
        display: inline-block;
        padding: 0 10px; }

.page-index .sec-notice .btn_wrap {
  text-align: center; }

.page-common .sec-header {
  background-color: #F6F8FA;
  padding: 4rem 0 1rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-header {
      padding-top: 2.5rem; } }

.page-common .sec-content {
  padding: 3.5rem 0 10rem; }
  .page-common .sec-content .grid-container {
    display: grid;
    grid-gap: 40px;
    grid-template: "main side" auto /1fr  340px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .grid-container {
        display: block; } }
    .page-common .sec-content .grid-container .grid-main {
      grid-area: main;
      overflow-x: auto; }
    .page-common .sec-content .grid-container .grid-side {
      grid-area: side; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .grid-container .grid-side {
          margin-top: 5em; } }
      .page-common .sec-content .grid-container .grid-side dl.side-contents .side-content {
        margin-bottom: 5rem; }
        .page-common .sec-content .grid-container .grid-side dl.side-contents .side-content > dt {
          margin-bottom: 1.5rem; }
          .page-common .sec-content .grid-container .grid-side dl.side-contents .side-content > dt::after {
            content: "";
            display: block;
            height: 2px;
            background-image: linear-gradient(to right, #E52019 60px, #DEDDDE 60px);
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: .5rem; }
  .page-common .sec-content section {
    margin-bottom: 4rem; }
    .page-common .sec-content section.mt-sec {
      margin-top: 4rem; }
  .page-common .sec-content .text {
    line-height: 1.5;
    font-size: .875rem; }
    .page-common .sec-content .text + .text {
      margin-top: 2.5em; }
  .page-common .sec-content .data-list {
    list-style: none;
    padding-left: 0; }
    .page-common .sec-content .data-list li {
      margin-bottom: 2.5rem; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .data-list li {
          margin-bottom: 1.5rem; } }
      .page-common .sec-content .data-list li a {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        color: inherit;
        line-height: 1.25;
        text-decoration: none;
        padding: 1.25rem 1rem 1.25rem 4.5rem;
        border: solid 1px #E9EFF4;
        border-radius: 5px; }
        .page-common .sec-content .data-list li a::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 1.5rem;
          margin: auto;
          width: 28px;
          height: 31px;
          background-image: url(../images/icon_pdf.jpg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
          @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
            .page-common .sec-content .data-list li a::before {
              background-image: url(../images/icon_pdf@2x.jpg); } }
    .page-common .sec-content .data-list.data-list-file li a::before {
      background-image: url(../images/icon_file.jpg); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .page-common .sec-content .data-list.data-list-file li a::before {
          background-image: url(../images/icon_file@2x.jpg); } }

.page-common .sec-content .page-donation .sec-donators {
  margin-top: 5rem; }
  .page-common .sec-content .page-donation .sec-donators .donators {
    list-style: none;
    padding-left: 0; }
    .page-common .sec-content .page-donation .sec-donators .donators li {
      padding: 2rem 0;
      border-bottom: dashed 1px #979797; }
      .page-common .sec-content .page-donation .sec-donators .donators li:first-of-type {
        border-top: dashed 1px #979797; }

.page-common .sec-content .page-member dl.expl {
  counter-reset: dt; }
  .page-common .sec-content .page-member dl.expl > div {
    overflow: hidden;
    margin-bottom: 2rem; }
    .page-common .sec-content .page-member dl.expl > div:nth-of-type(2) > dd p {
      padding-left: 3em; }
    .page-common .sec-content .page-member dl.expl > div > dt {
      float: left;
      margin-right: 1em;
      counter-increment: dt; }
      .page-common .sec-content .page-member dl.expl > div > dt::before {
        content: counter(dt) ".";
        display: inline-block;
        min-width: 1em; }
    .page-common .sec-content .page-member dl.expl > div > dd dl.fee > div {
      display: inline-flex;
      margin-right: 3em; }
      .page-common .sec-content .page-member dl.expl > div > dd dl.fee > div:last-of-type {
        margin-right: 0; }
      .page-common .sec-content .page-member dl.expl > div > dd dl.fee > div dt, .page-common .sec-content .page-member dl.expl > div > dd dl.fee > div dd {
        flex: none; }
      .page-common .sec-content .page-member dl.expl > div > dd dl.fee > div dt::after {
        content: "　･･･　"; }

.page-common .sec-content .page-access .map {
  width: 100%;
  min-height: 600px;
  margin-bottom: 5rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .page-access .map {
      min-height: 300px;
      margin-bottom: 2rem; } }

.page-common .sec-content .page-access address {
  font-size: 1.125rem;
  font-style: normal; }
  .page-common .sec-content .page-access address .text + .text {
    margin-top: 1.5em; }

.page-common .sec-content .page-access .table_access {
  width: 100%;
  text-align: left;
  background: #eee;
  border-collapse: collapse; }
  .page-common .sec-content .page-access .table_access tr:nth-child(2n+1) {
    background-color: rgba(255, 255, 255, 0.5); }
  .page-common .sec-content .page-access .table_access th, .page-common .sec-content .page-access .table_access td {
    padding: 10px;
    font-size: 87.5%;
    line-height: 1.3;
    border: 1px solid #ddd; }

.page-common.page-lack .sec-content {
  padding-bottom: 0; }
  .page-common.page-lack .sec-content section {
    margin-bottom: 8rem; }
  .page-common.page-lack .sec-content .prelude {
    margin-bottom: 4rem; }
  .page-common.page-lack .sec-content .lack-title {
    font-size: 1.125rem;
    font-weight: normal;
    margin-bottom: 2rem; }
  .page-common.page-lack .sec-content dl.lack-types .lack-type dt {
    color: #D43F3A;
    font-weight: normal;
    margin-bottom: .5rem; }
  .page-common.page-lack .sec-content dl.lack-types .lack-type dd table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 2rem; }
    .page-common.page-lack .sec-content dl.lack-types .lack-type dd table th, .page-common.page-lack .sec-content dl.lack-types .lack-type dd table td {
      font-size: .875rem;
      line-height: 1.375;
      text-align: left;
      border: solid 1px #DEDDDE; }
    .page-common.page-lack .sec-content dl.lack-types .lack-type dd table th {
      width: 308px;
      background-color: #FFF7F7;
      font-weight: normal;
      padding: .75rem; }
    .page-common.page-lack .sec-content dl.lack-types .lack-type dd table td {
      padding: .75rem 1rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common.page-lack .sec-content dl.lack-types .lack-type dd table {
        display: block; }
        .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tbody, .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tr, .page-common.page-lack .sec-content dl.lack-types .lack-type dd table th, .page-common.page-lack .sec-content dl.lack-types .lack-type dd table td {
          display: block;
          width: auto; }
        .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tbody th, .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tbody td {
          border-bottom-style: none; }
          .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tbody th:empty::before, .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tbody td:empty::before {
            content: "\00a0"; }
        .page-common.page-lack .sec-content dl.lack-types .lack-type dd table tbody tr:last-of-type > :last-child {
          border-bottom-style: solid; } }

.page-common.page-lack .sec-lack-form .lack-form-title {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.125;
  margin-bottom: 4rem; }
  .page-common.page-lack .sec-lack-form .lack-form-title::after {
    content: "";
    display: block;
    height: 2px;
    background-image: linear-gradient(to right, #E52019 60px, #DEDDDE 60px);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: .5rem; }

.page-common.page-lack .sec-lack-form .lack-form {
  padding: 3rem 1.75rem 4rem;
  border: solid 1px #4A90E2;
  margin-bottom: 10rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common.page-lack .sec-lack-form .lack-form {
      padding: 0;
      border: none; } }
  .page-common.page-lack .sec-lack-form .lack-form table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 2rem; }
    .page-common.page-lack .sec-lack-form .lack-form table th, .page-common.page-lack .sec-lack-form .lack-form table td {
      font-size: .875rem;
      line-height: 1.375;
      text-align: left;
      border: solid 1px #DEDDDE; }
    .page-common.page-lack .sec-lack-form .lack-form table th {
      width: 308px;
      background-color: #F4F8FD;
      font-weight: normal;
      padding: .75rem 5rem .75rem .75rem; }
      .page-common.page-lack .sec-lack-form .lack-form table th.required {
        position: relative; }
        .page-common.page-lack .sec-lack-form .lack-form table th.required::after {
          content: "必須";
          position: absolute;
          top: 0;
          bottom: 0;
          right: .625rem;
          margin: auto;
          background-color: #D43F3A;
          color: white;
          font-size: .75rem;
          text-align: center;
          width: 4rem;
          height: 1.25rem;
          line-height: 1.25rem;
          border-radius: 4px; }
    .page-common.page-lack .sec-lack-form .lack-form table td {
      padding: .5rem; }
      .page-common.page-lack .sec-lack-form .lack-form table td .flex-wrapper {
        display: flex;
        align-items: center; }
        .page-common.page-lack .sec-lack-form .lack-form table td .flex-wrapper .input-text {
          flex: none;
          display: inline-block;
          min-width: 2em;
          text-align: center;
          margin: 0 .5em; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            .page-common.page-lack .sec-lack-form .lack-form table td .flex-wrapper .input-text {
              min-width: 0; }
              .page-common.page-lack .sec-lack-form .lack-form table td .flex-wrapper .input-text:first-child {
                margin-left: 0; } }
        .page-common.page-lack .sec-lack-form .lack-form table td .flex-wrapper input {
          flex: 1 1 0%; }
          @media screen and (min-width: 0) and (max-width: 878px) {
            .page-common.page-lack .sec-lack-form .lack-form table td .flex-wrapper input {
              min-width: 0; } }
      .page-common.page-lack .sec-lack-form .lack-form table td .adjust-wrapper {
        padding-left: 3em; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common.page-lack .sec-lack-form .lack-form table td .adjust-wrapper {
            padding-left: 0; } }
      .page-common.page-lack .sec-lack-form .lack-form table td input, .page-common.page-lack .sec-lack-form .lack-form table td textarea {
        font-size: inherit;
        padding: .5rem .75rem;
        border: solid 1px #DEDDDE;
        border-radius: 4px; }
        .page-common.page-lack .sec-lack-form .lack-form table td input.full-width, .page-common.page-lack .sec-lack-form .lack-form table td textarea.full-width {
          width: 100%; }
      .page-common.page-lack .sec-lack-form .lack-form table td textarea {
        line-height: 1.5;
        min-height: 200px;
        resize: vertical; }
      .page-common.page-lack .sec-lack-form .lack-form table td .err-msg {
        color: #D43F3A;
        font-size: .875rem;
        line-height: 1.5;
        margin: .25rem 0 -.25rem; }
        .page-common.page-lack .sec-lack-form .lack-form table td .err-msg:empty {
          display: none; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common.page-lack .sec-lack-form .lack-form table {
        display: block; }
        .page-common.page-lack .sec-lack-form .lack-form table tbody, .page-common.page-lack .sec-lack-form .lack-form table tr, .page-common.page-lack .sec-lack-form .lack-form table th, .page-common.page-lack .sec-lack-form .lack-form table td {
          display: block;
          width: auto; }
        .page-common.page-lack .sec-lack-form .lack-form table tbody th, .page-common.page-lack .sec-lack-form .lack-form table tbody td {
          border-bottom-style: none; }
          .page-common.page-lack .sec-lack-form .lack-form table tbody th:empty::before, .page-common.page-lack .sec-lack-form .lack-form table tbody td:empty::before {
            content: "\00a0"; }
        .page-common.page-lack .sec-lack-form .lack-form table tbody tr:last-of-type > :last-child {
          border-bottom-style: solid; } }
  .page-common.page-lack .sec-lack-form .lack-form .lack-form-submit {
    text-align: right; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common.page-lack .sec-lack-form .lack-form .lack-form-submit {
        text-align: center; } }
    .page-common.page-lack .sec-lack-form .lack-form .lack-form-submit .text {
      text-align: center;
      margin-bottom: 2rem; }
    .page-common.page-lack .sec-lack-form .lack-form .lack-form-submit .lack-form-btn {
      display: inline-block;
      min-width: 300px;
      background: #D43F3A;
      color: white;
      font-size: 1.125rem;
      letter-spacing: 1em;
      text-indent: 1em;
      padding: .5rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: opacity .2s; }
      .page-common.page-lack .sec-lack-form .lack-form .lack-form-submit .lack-form-btn:hover {
        filter: alpha(opacity=70);
        -moz-opacity: 0.7;
        opacity: 0.7; }
      .page-common.page-lack .sec-lack-form .lack-form .lack-form-submit .lack-form-btn.lack-form-btn-back {
        background-color: #4A90E2; }

.page-common.page-lack.page-lack-confirmation .sec-lack-form .lack-form table td {
  padding: .5rem .75rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common.page-lack.page-lack-confirmation .sec-lack-form .lack-form table td {
      padding: .75rem; } }

.page-common.page-lack.page-lack-confirmation .sec-lack-form .lack-form .lack-form-submit {
  text-align: center; }

.page-common.page-lack.page-lack-completed .sec-lack-form .lack-form h2 {
  font-size: 1.25rem;
  margin-bottom: 2rem; }

.page-common.page-lack.page-lack-completed .sec-lack-form .lack-form .text {
  line-height: 1.5; }
  .page-common.page-lack.page-lack-completed .sec-lack-form .lack-form .text + .text {
    margin-top: 1.25rem; }

.page-common .sec-content .page-history .sec-visual {
  margin-bottom: 5rem; }
  .page-common .sec-content .page-history .sec-visual .visual-wrapper {
    position: relative;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/history/visual.jpg);
    padding-top: 55.641025641025641%; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .page-common .sec-content .page-history .sec-visual .visual-wrapper {
        background-image: url(../images/history/visual@2x.jpg); } }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-history .sec-visual .visual-wrapper {
        background: #FBBA40;
        padding: 1.5rem 1rem;
        border-radius: 5px; } }
    .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods {
      height: 0; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods {
          height: auto; } }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dt {
        position: absolute;
        font-size: 1.875rem;
        font-weight: bold;
        white-space: nowrap; }
        @media screen and (max-width: 1000px) {
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dt {
            font-size: 1.5rem; } }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dt {
            position: static;
            font-size: 1.25rem;
            white-space: normal;
            margin-bottom: 1rem; } }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd {
        position: absolute;
        top: 48%;
        width: 14.615384615384615%; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd {
            position: static;
            width: auto; } }
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd ol.eras {
          list-style: none;
          padding-left: 0; }
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd ol.eras li {
            margin-bottom: 5px; }
            .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd ol.eras li a {
              position: relative;
              display: block;
              background-color: white;
              color: inherit;
              font-size: .8125rem;
              font-weight: bold;
              padding: .75em 1.375em .75em 1.25em;
              border-radius: 1000px; }
              @media screen and (max-width: 1000px) {
                .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd ol.eras li a {
                  font-size: .75rem; } }
              @media screen and (min-width: 0) and (max-width: 878px) {
                .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd ol.eras li a {
                  font-size: 1rem; } }
              .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods dd ol.eras li a::after {
                content: "↓";
                display: inline-block;
                position: absolute;
                top: 0;
                right: .625em;
                bottom: 0;
                margin: auto;
                height: 1em;
                line-height: 1; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .ancient,
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .middle {
          margin-bottom: 2rem; } }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .ancient dt::before,
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .middle dt::before,
        .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .modern dt::before {
          content: "\00a0";
          display: inline-block;
          width: 1em;
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: .25em; } }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .ancient dt {
        top: 25%;
        right: 74%; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .ancient dt::before {
            background-image: url(../images/history/icon_ancient.png); } }
  @media only screen and (min-width: 0) and (max-width: 878px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 0) and (max-width: 878px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 0) and (max-width: 878px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-width: 0) and (max-width: 878px) and (min-resolution: 192dpi), only screen and (min-width: 0) and (max-width: 878px) and (min-resolution: 2dppx) {
    .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .ancient dt::before {
      background-image: url(../images/history/icon_ancient@2x.png); } }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .ancient dd {
        left: 18.75%; }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .middle dt {
        bottom: 15%;
        left: 47%; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .middle dt::before {
            background-image: url(../images/history/icon_middle.png); } }
  @media only screen and (min-width: 0) and (max-width: 878px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 0) and (max-width: 878px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 0) and (max-width: 878px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-width: 0) and (max-width: 878px) and (min-resolution: 192dpi), only screen and (min-width: 0) and (max-width: 878px) and (min-resolution: 2dppx) {
    .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .middle dt::before {
      background-image: url(../images/history/icon_middle@2x.png); } }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .middle dd {
        left: 40%; }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .modern dt {
        top: 23%;
        right: 32%; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .modern dt::before {
            background-image: url(../images/history/icon_modern.png); } }
  @media only screen and (min-width: 0) and (max-width: 878px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 0) and (max-width: 878px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 0) and (max-width: 878px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-width: 0) and (max-width: 878px) and (min-resolution: 192dpi), only screen and (min-width: 0) and (max-width: 878px) and (min-resolution: 2dppx) {
    .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .modern dt::before {
      background-image: url(../images/history/icon_modern@2x.png); } }
      .page-common .sec-content .page-history .sec-visual .visual-wrapper dl.periods .modern dd {
        left: 61.25%; }

.page-common .sec-content .page-history .sec-periods section {
  margin-bottom: 9rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .page-history .sec-periods section {
      margin-bottom: 5rem; } }
  .page-common .sec-content .page-history .sec-periods section.era9 {
    margin-top: -3rem; }
    .page-common .sec-content .page-history .sec-periods section.era9 .period-header {
      padding-top: 3rem;
      margin-bottom: 4rem; }

.page-common .sec-content .page-history .sec-periods .text {
  line-height: 2; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .page-history .sec-periods .text {
      text-align: left; }
      .page-common .sec-content .page-history .sec-periods .text br {
        display: none; } }

.page-common .sec-content .page-history .sec-periods .period-header {
  color: #FBBA40;
  font-size: 2.25rem;
  font-weight: normal;
  margin-bottom: 1rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .page-history .sec-periods .period-header {
      font-size: 1.75rem; } }

.page-common .sec-content .page-history .sec-periods .flex-wrapper {
  display: flex; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .page-history .sec-periods .flex-wrapper {
      flex-direction: column; } }
  .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-left,
  .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-right {
    flex: 1 1 0%; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-left,
      .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-right {
        order: 2;
        text-align: center; } }
    .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-left.flex-none,
    .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-right.flex-none {
      flex: none; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-left.flex-none,
        .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-right.flex-none {
          order: 1;
          margin-bottom: 2rem; } }
  .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-left {
    margin-right: 40px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-history .sec-periods .flex-wrapper .flex-left {
        margin-right: 0; } }

.page-common .sec-content .page-history .sec-periods dl.eras .era {
  margin-bottom: 1rem; }
  .page-common .sec-content .page-history .sec-periods dl.eras .era dt {
    position: relative;
    z-index: 1;
    font-size: 1.3125rem;
    font-weight: bold;
    padding-top: 3rem;
    padding-bottom: 3rem; }
    .page-common .sec-content .page-history .sec-periods dl.eras .era dt span {
      display: inline-block;
      background-color: white;
      padding-right: 2em; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-periods dl.eras .era dt span {
          padding-right: 1em; } }
    .page-common .sec-content .page-history .sec-periods dl.eras .era dt::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      height: 1px;
      background-color: #979797; }
  .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement {
    background-color: #F4F3EE;
    padding: 3rem 4rem;
    margin-top: 4rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement {
        padding: 2rem 1rem; } }
    .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement .era-supplement-header {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 3rem; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement .era-supplement-header {
          margin-bottom: 2rem; } }
    .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement.era-supplement-era3 .era-supplement-header {
      margin-bottom: 0; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement.era-supplement-era3 .era-supplement-header {
          margin-bottom: 2rem; } }
    .page-common .sec-content .page-history .sec-periods dl.eras .era dd .era-supplement.era-supplement-era3 .align-center {
      margin-bottom: 2rem; }

.page-common .sec-content .page-traditional-crafts .text {
  line-height: 1.5; }
  .page-common .sec-content .page-traditional-crafts .text + .text {
    margin-top: 1.75em; }

.page-common .sec-content .page-traditional-crafts dl.pdfs {
  margin-top: 2rem; }
  .page-common .sec-content .page-traditional-crafts dl.pdfs dt {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 2rem; }

.page-common .sec-content .page-law .common-h2 {
  margin-bottom: 4rem; }

.page-common .sec-content .page-law .text {
  line-height: 2; }
  .page-common .sec-content .page-law .text + .text {
    margin-top: 1.75em; }

.page-common .sec-content .page-law .links {
  margin-top: 2rem; }
  .page-common .sec-content .page-law .links dt {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 2rem; }

.page-common .sec-content .page-current-situtation .text {
  line-height: 2; }
  .page-common .sec-content .page-current-situtation .text + .text {
    margin-top: 1.75em; }

.page-common .sec-content .page-current-situtation dl.current-situtation {
  margin-bottom: 6rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .page-current-situtation dl.current-situtation {
      margin-bottom: 4rem; } }
  .page-common .sec-content .page-current-situtation dl.current-situtation dt {
    position: relative;
    z-index: 1;
    font-size: 1.3125rem;
    font-weight: bold;
    padding-top: 3rem;
    padding-bottom: 3rem; }
    .page-common .sec-content .page-current-situtation dl.current-situtation dt span {
      display: inline-block;
      background-color: white;
      padding-right: 2em; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-current-situtation dl.current-situtation dt span {
          padding-right: 1em; } }
    .page-common .sec-content .page-current-situtation dl.current-situtation dt::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      height: 1px;
      background-color: #979797; }
  .page-common .sec-content .page-current-situtation dl.current-situtation dd {
    overflow: hidden; }
    .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table {
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 10px;
      max-width: 720px;
      width: 100%;
      margin: -10px; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table {
          display: block;
          margin: 0; }
          .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table thead {
            display: none; }
          .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody, .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tr, .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table th, .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table td {
            display: block; } }
      .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table th {
        font-weight: normal;
        line-height: 1.75; }
      .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table thead th {
        background-color: #F4F3EE;
        padding: .25rem; }
      .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody th, .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody td {
        text-align: left;
        font-weight: normal;
        line-height: 1.5;
        padding: .5rem 1rem; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody tr {
          margin-bottom: 1.5rem; }
        .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody th, .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody td {
          padding: .25rem 0; }
        .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody th {
          font-weight: bold; }
        .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody td {
          display: flex;
          align-items: center; }
          .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody td::before {
            flex: none;
            align-self: stretch;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #F4F3EE;
            min-width: 7em;
            font-size: .875rem;
            text-align: center;
            padding: .25rem;
            margin-right: .5em; }
          .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody td:nth-of-type(1)::before {
            content: "平成24年度"; }
          .page-common .sec-content .page-current-situtation dl.current-situtation dd .current-situtation-table tbody td:nth-of-type(2)::before {
            content: "参考"; } }

.page-common .sec-content .page-producers .producers-wrapper {
  overflow: auto; }

.page-common .sec-content .page-producers table.producers {
  border-collapse: collapse;
  font-size: .875rem;
  line-height: 1.25;
  margin-top: 1rem; }
  .page-common .sec-content .page-producers table.producers thead th {
    background-color: #F4F3EE;
    font-weight: normal; }
  .page-common .sec-content .page-producers table.producers thead th.mark {
    font-size: .75rem;
    box-sizing: content-box;
    width: 2em;
    max-width: 2em;
    line-height: 1.125; }
    .page-common .sec-content .page-producers table.producers thead th.mark span {
      display: inline-block; }
  .page-common .sec-content .page-producers table.producers tbody tr:nth-of-type(even) {
    background-color: #eee; }
  .page-common .sec-content .page-producers table.producers tbody tr td {
    padding: .5rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-producers table.producers tbody tr td {
        padding: .5rem .25rem; } }
    .page-common .sec-content .page-producers table.producers tbody tr td:empty::before {
      content: "-";
      color: #bbb; }
    .page-common .sec-content .page-producers table.producers tbody tr td:first-of-type {
      text-align: center; }
    .page-common .sec-content .page-producers table.producers tbody tr td:nth-of-type(-n + 4) {
      white-space: nowrap; }
    .page-common .sec-content .page-producers table.producers tbody tr td:nth-of-type(n + 6) {
      text-align: center; }
    .page-common .sec-content .page-producers table.producers tbody tr td:nth-of-type(2) a::before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      background-image: url(../images/icon_pdf.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: -.125em;
      mix-blend-mode: darken; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .page-common .sec-content .page-producers table.producers tbody tr td:nth-of-type(2) a::before {
          background-image: url(../images/icon_pdf@2x.jpg); } }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-producers table.producers tbody tr td:nth-of-type(3) {
        box-sizing: content-box;
        min-width: 2em;
        white-space: normal; } }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .page-producers table.producers tbody tr td:nth-of-type(4) {
        box-sizing: content-box;
        min-width: 3em;
        white-space: normal; } }

.page-common .sec-content .single-hall .single-hall-header {
  color: #E52019;
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 1.75rem; }

.page-common .sec-content .single-hall .grid-container .grid-main .main_title_wrap {
  display: flex;
  margin-bottom: 4rem; }
  .page-common .sec-content .single-hall .grid-container .grid-main .main_title_wrap .main_title {
    flex-grow: 2;
    margin-bottom: 0; }
  .page-common .sec-content .single-hall .grid-container .grid-main .main_title_wrap .main_title_img {
    padding-right: 20px;
    align-self: center; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .single-hall .grid-container .grid-main .main_title_wrap .main_title_img {
        display: block;
        padding-right: 10px;
        text-align: center; } }
    .page-common .sec-content .single-hall .grid-container .grid-main .main_title_wrap .main_title_img img {
      max-height: 80px;
      width: auto; }

.page-common .sec-content .single-hall .grid-container .grid-main .title_sub {
  font-size: .875rem;
  margin-bottom: .5rem; }

.page-common .sec-content .single-hall .grid-container .grid-main .main_title {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.25;
  border-color: black;
  border-style: dashed none;
  border-width: 1px;
  padding: 1.5rem 0;
  margin-bottom: 4rem; }

.page-common .sec-content .single-hall .grid-container .grid-main .hall_table {
  width: 100%;
  border-collapse: collapse;
  font-size: .875rem;
  margin-bottom: 3rem; }
  .page-common .sec-content .single-hall .grid-container .grid-main .hall_table th, .page-common .sec-content .single-hall .grid-container .grid-main .hall_table td {
    border: solid 1px #DEDDDE;
    text-align: left;
    line-height: 1.75; }
  .page-common .sec-content .single-hall .grid-container .grid-main .hall_table th {
    width: 194px;
    background-color: #ECECED;
    font-weight: normal;
    padding: .75rem; }
  .page-common .sec-content .single-hall .grid-container .grid-main .hall_table td {
    padding: .75rem 1.5rem; }
  .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tr.exhibits ul {
    margin-top: 5px;
    padding-left: 0; }
    .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tr.exhibits ul li {
      display: inline-block;
      margin: 0 5px; }
      .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tr.exhibits ul li img {
        max-width: 100px; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .single-hall .grid-container .grid-main .hall_table {
      display: block; }
      .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tbody, .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tr, .page-common .sec-content .single-hall .grid-container .grid-main .hall_table th, .page-common .sec-content .single-hall .grid-container .grid-main .hall_table td {
        display: block;
        width: auto; }
      .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tbody th, .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tbody td {
        border-bottom-style: none; }
        .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tbody th:empty::before, .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tbody td:empty::before {
          content: "\00a0"; }
      .page-common .sec-content .single-hall .grid-container .grid-main .hall_table tbody tr:last-of-type > :last-child {
        border-bottom-style: solid; } }

.page-common .sec-content .single-hall .grid-container .grid-main .hall_title {
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 1.5rem; }

.page-common .sec-content .single-hall .grid-container .grid-main .hall_text {
  font-size: .875rem;
  line-height: 1.75;
  padding: .75rem 1.5rem;
  border: solid 1px #DEDDDE;
  margin-bottom: 3rem; }

.page-common .sec-content .single-hall .grid-container .grid-main .more_list {
  display: none; }

.page-common .sec-content .single-hall .grid-container .grid-side dl.halls {
  font-size: .875rem; }
  .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dt, .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd {
    margin-bottom: .5rem; }
  .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dt {
    color: #E52019;
    font-weight: normal;
    margin-top: 20px; }
  .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul {
    line-height: 1.75;
    padding-left: 1em; }
    .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li {
      list-style: none; }
      .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a {
        color: inherit;
        text-decoration: none; }
        .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a:hover, .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a:focus {
          text-decoration: underline; }
        .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content {
          margin: 5px 0;
          display: table;
          width: 100%; }
          .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content .img, .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content .title {
            display: table-cell;
            vertical-align: middle; }
          .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content .img {
            width: 15%;
            transition: opacity .2s; }
            .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content .img:hover {
              filter: alpha(opacity=70);
              -moz-opacity: 0.7;
              opacity: 0.7; }
            .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content .img img {
              vertical-align: bottom; }
          .page-common .sec-content .single-hall .grid-container .grid-side dl.halls dd ul li a .nav_content .title {
            padding-left: 15px; }

.page-common .sec-content .archive-info .grid-container .grid-main .notices.single .notice {
  border: none;
  padding: 0; }

.page-common .sec-content .archive-info .grid-container .grid-main .link_title a {
  color: #000; }
  .page-common .sec-content .archive-info .grid-container .grid-main .link_title a:hover {
    text-decoration: underline; }

.page-common .sec-content .archive-info .grid-container .grid-main .btn_wrap {
  text-align: right; }

.page-common .sec-content .archive-info .grid-container .grid-side .side-notices {
  line-height: 1.5;
  font-size: .75rem; }
  .page-common .sec-content .archive-info .grid-container .grid-side .side-notices > div {
    margin-bottom: 1rem; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .page-common .sec-content .archive-info .grid-container .grid-side .side-notices > div dd.time {
        font-size: .875rem; } }
    .page-common .sec-content .archive-info .grid-container .grid-side .side-notices > div dt {
      font-weight: bold; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .archive-info .grid-container .grid-side .side-notices > div dt {
          font-size: 1rem;
          font-weight: normal; } }
      .page-common .sec-content .archive-info .grid-container .grid-side .side-notices > div dt a {
        color: inherit; }
        @media screen and (min-width: 0) and (max-width: 878px) {
          .page-common .sec-content .archive-info .grid-container .grid-side .side-notices > div dt a {
            color: #0C53A4; } }

.page-common .sec-content .archive-info .grid-container .grid-side .side-archives {
  line-height: 1.5;
  font-size: .8125rem;
  list-style: none;
  padding-left: 0; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-common .sec-content .archive-info .grid-container .grid-side .side-archives {
      font-size: 1rem; } }
  .page-common .sec-content .archive-info .grid-container .grid-side .side-archives li {
    margin-bottom: .5rem; }
    .page-common .sec-content .archive-info .grid-container .grid-side .side-archives li a {
      color: inherit;
      text-decoration: none; }
      .page-common .sec-content .archive-info .grid-container .grid-side .side-archives li a:hover, .page-common .sec-content .archive-info .grid-container .grid-side .side-archives li a:focus {
        text-decoration: underline; }
      @media screen and (min-width: 0) and (max-width: 878px) {
        .page-common .sec-content .archive-info .grid-container .grid-side .side-archives li a {
          color: #0C53A4; } }

.page-common .sec-content .archive-hall .common-h2 {
  margin-bottom: 4rem; }

.page-common .sec-content .archive-hall .halls .hall {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  display: table;
  width: 100%;
  overflow: hidden; }
  .page-common .sec-content .archive-hall .halls .hall .img_box, .page-common .sec-content .archive-hall .halls .hall .text_box {
    display: table-cell;
    vertical-align: middle; }
  .page-common .sec-content .archive-hall .halls .hall .img_box {
    width: 35%; }
    .page-common .sec-content .archive-hall .halls .hall .img_box a {
      transition: opacity .2s;
      display: block;
      box-sizing: border-box; }
      .page-common .sec-content .archive-hall .halls .hall .img_box a:hover {
        filter: alpha(opacity=70);
        -moz-opacity: 0.7;
        opacity: 0.7; }
    .page-common .sec-content .archive-hall .halls .hall .img_box img {
      vertical-align: bottom;
      max-width: 100%; }
  .page-common .sec-content .archive-hall .halls .hall .text_box {
    width: 65%;
    padding-left: 10px;
    padding-right: 10px; }
    .page-common .sec-content .archive-hall .halls .hall .text_box .title a {
      font-size: 0.9rem;
      color: #000; }
    .page-common .sec-content .archive-hall .halls .hall .text_box .info {
      margin-top: 5px; }
      .page-common .sec-content .archive-hall .halls .hall .text_box .info .pref, .page-common .sec-content .archive-hall .halls .hall .text_box .info .member {
        display: inline-block;
        vertical-align: middle; }
      .page-common .sec-content .archive-hall .halls .hall .text_box .info .pref {
        /*font-size: .75rem;
						color: #888;
						font-weight: bold;
						margin-right: 1rem;*/
        font-size: .5rem;
        min-width: 6.5em;
        background-color: #f3abab;
        color: white;
        line-height: 20px;
        text-align: center;
        border-radius: 100rem;
        margin-right: .5em; }
      .page-common .sec-content .archive-hall .halls .hall .text_box .info .member {
        font-size: .75rem;
        color: #E52019; }
