.page-common .sec-content .archive-hall {
	.common-h2 {
		margin-bottom: 4rem;
	}
	.halls {
		.hall {
			margin-bottom: 1rem;
			border:1px solid #ddd;
			display: table;
			width: 100%;
			overflow: hidden;
			.img_box,.text_box{
				display: table-cell;
				vertical-align: middle;
			}
			.img_box{
				width: 35%;
				a{
					@include hover;
					display: block;
					box-sizing: border-box;
				}
				img{
					vertical-align: bottom;
					max-width: 100%;
				}
			}
			.text_box{
				width: 65%;
				padding-left: 10px;
				padding-right: 10px;
				.title{
					a{
						font-size: 0.9rem;
						color:#000;
					}
				}
				.info{
					margin-top: 5px;
					.pref,.member {
						display: inline-block;
						vertical-align: middle;
					}
					.pref {
						/*font-size: .75rem;
						color: #888;
						font-weight: bold;
						margin-right: 1rem;*/

						font-size: .5rem;
						min-width: 6.5em;
						background-color: #f3abab;
						color: white;
						line-height: 20px;
						text-align: center;
						border-radius: 100rem;
						margin-right: .5em;

					}
					.member {
						font-size: .75rem;
						color: $main-color;
					}
				}

			}
		}
	}
}
