.page-common.page-lack {
	.sec-content {
		padding-bottom: 0;
		section {
			margin-bottom: 8rem;
		}
		.prelude {
			margin-bottom: 4rem;
		}
		.lack-title {
			font-size: 1.125rem;
			font-weight: normal;
			margin-bottom: 2rem;
		}
		dl.lack-types {
			.lack-type {
				dt {
					color: #D43F3A;
					font-weight: normal;
					margin-bottom: .5rem;
				}
				dd {
					table {
						width: 100%;
						table-layout: fixed;
						border-collapse: collapse;
						margin-bottom: 2rem;
						th, td {
							font-size: .875rem;
							line-height: 1.375;
							text-align: left;
							border: solid 1px #DEDDDE;
						}
						th {
							width: 308px;
							background-color: #FFF7F7;
							font-weight: normal;
							padding: .75rem;
						}
						td {
							padding: .75rem 1rem;
						}
						@include sp {
							display: block;
							tbody, tr, th, td {
								display: block;
								width: auto;
							}
							tbody {
								th, td {
									border-bottom-style: none;
									&:empty {
										&::before {
											content: "\00a0";
										}
									}
								}
								tr:last-of-type > :last-child {
									border-bottom-style: solid;
								}
							}
						}
					}
				}
			}
		}
	}
	.sec-lack-form {
		.lack-form-title {
			font-size: 1.25rem;
			font-weight: normal;
			line-height: 1.125;
			margin-bottom: 4rem;
			&::after {
				content: "";
				display: block;
				height: 2px;
				background-image: linear-gradient(to right, $main-color 60px, #DEDDDE 60px);
				background-size: 100% auto;
				background-repeat: no-repeat;
				background-position: center;
				margin-top: .5rem;
			}
		}
		.lack-form {
			padding: 3rem 1.75rem 4rem;
			border: solid 1px #4A90E2;
			margin-bottom: 10rem;
			@include sp {
				padding: 0;
				border: none;
			}
			table {
				width: 100%;
				table-layout: fixed;
				border-collapse: collapse;
				margin-bottom: 2rem;
				th, td {
					font-size: .875rem;
					line-height: 1.375;
					text-align: left;
					border: solid 1px #DEDDDE;
				}
				th {
					width: 308px;
					background-color: #F4F8FD;
					font-weight: normal;
					padding: .75rem 5rem .75rem .75rem;
					&.required {
						position: relative;
						&::after {
							content: "必須";
							position: absolute;
							top: 0;
							bottom: 0;
							right: .625rem;
							margin: auto;
							background-color: #D43F3A;
							color: white;
							font-size: .75rem;
							text-align: center;
							width: 4rem;
							height: 1.25rem;
							line-height: 1.25rem;
							border-radius: 4px;
						}
					}
				}
				td {
					padding: .5rem;
					.flex-wrapper {
						display: flex;
						align-items: center;
						.input-text {
							flex: none;
							display: inline-block;
							min-width: 2em;
							text-align: center;
							margin: 0 .5em;
							@include sp {
								min-width: 0;
								&:first-child {
									margin-left: 0;
								}
							}
						}
						input {
							flex: 1 1 0%;
							@include sp {
								min-width: 0;
							}
						}
					}
					.adjust-wrapper {
						padding-left: 3em;
						@include sp {
							padding-left: 0;
						}
					}
					input, textarea {
						font-size: inherit;
						padding: .5rem .75rem;
						border: solid 1px #DEDDDE;
						border-radius: 4px;
						&.full-width {
							width: 100%;
						}
					}
					textarea {
						line-height: 1.5;
						min-height: 200px;
						resize: vertical;
					}
					.err-msg {
						color: #D43F3A;
						font-size: .875rem;
						line-height: 1.5;
						margin: .25rem 0 -.25rem;
						&:empty {
							display: none;
						}
					}
				}
				@include sp {
					display: block;
					tbody, tr, th, td {
						display: block;
						width: auto;
					}
					tbody {
						th, td {
							border-bottom-style: none;
							&:empty {
								&::before {
									content: "\00a0";
								}
							}
						}
						tr:last-of-type > :last-child {
							border-bottom-style: solid;
						}
					}
				}
			}
			.lack-form-submit {
				text-align: right;
				@include sp {
					text-align: center;
				}
				.text {
					text-align: center;
					margin-bottom: 2rem;
				}
				.lack-form-btn {
					display: inline-block;
					min-width: 300px;
					background: #D43F3A;
					color: white;
					font-size: 1.125rem;
					letter-spacing: 1em;
					text-indent: 1em;
					padding: .5rem;
					border: none;
					border-radius: 4px;
					cursor: pointer;
					@include hover;
					&.lack-form-btn-back {
						background-color: #4A90E2;
					}
				}
			}
		}
	}
}

.page-common.page-lack.page-lack-confirmation {
	.sec-lack-form {
		.lack-form {
			table {
				td {
					padding: .5rem .75rem;
					@include sp {
						padding: .75rem;
					}
				}
			}
			.lack-form-submit {
				text-align: center;
				form[name="contact_frm"] {

				}
			}
		}
	}
}

.page-common.page-lack.page-lack-completed {
	.sec-lack-form {
		.lack-form {
			h2 {
				font-size: 1.25rem;
				margin-bottom: 2rem;
			}
			.text {
				line-height: 1.5;
				& + .text {
					margin-top: 1.25rem;
				}
			}
		}
	}
}
